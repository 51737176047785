import { Autocomplete, Chip, CircularProgress, debounce, ListItem, TextField } from "@mui/material";
import { uniqBy } from "lodash-es";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { substringMatch } from "#components/Highlight/index.tsx";
import { Highlight } from "#components/index.ts";
import CreateEmbeddedResource from "#containers/DataEditor/Actions/CreateEmbeddedResource.tsx";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { MAX_SEARCH_RESULTS } from "../..";
import { useEditorProcessContext } from "../../Process";
import { FormValues, IriProperty } from "../Types";
import { Editor } from "./Editor";
import * as styles from "./style.scss";

const factory = factories.compliant;

const THERE_IS_MORE_KEY = "_";

interface Item {
  value: string;
  label?: string;
  description?: string;
}

const AutoCompleteEditor: Editor = {
  id: "http://datashapes.org/dash#AutoCompleteEditor",
  Component: ({ name, propertyModel }) => {
    const { control, watch } = useFormContext<FormValues>();
    const val = watch(name);
    const applyPrefixes = useApplyPrefixes();
    const [initialValue] = React.useState<IriProperty | null>(val as IriProperty);
    const [focussed, setFocussed] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState<readonly Item[]>([]);
    const [optionsFor, setOptionsFor] = React.useState("");
    const [createNew, setCreateNew] = React.useState<string>();
    const { getCreateActions } = useEditorProcessContext();
    const createActions = getCreateActions();
    const selectedAction = createActions.find((action) => action.name === createNew);
    const currentDs = useCurrentDataset()!;
    const sparqlUrl = useConstructUrlToApi()({
      pathname: `/_console/sparql`,
      fromBrowser: true,
    });

    const [isLoadingOptions, setIsLoadingOptions] = React.useState(false);

    const sparql = React.useCallback(
      async (query: string, abortSignal: AbortSignal) => {
        const response = await fetch(sparqlUrl, {
          credentials: "same-origin",
          signal: abortSignal,
          method: "POST",
          headers: { Accept: "application/json" },
          body: new URLSearchParams({
            account: currentDs.owner.accountName,
            dataset: currentDs.name,
            queryString: query,
          }),
        });
        if (!response.ok) throw new Error(response.statusText);
        const result = await response.json();
        return result;
      },
      [sparqlUrl, currentDs.owner.accountName, currentDs.name],
    );

    const debouncedQuery = React.useMemo(
      () =>
        debounce(
          (
            { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
            callback: (results?: readonly Item[]) => void,
          ) => {
            if (!propertyModel.type) return;
            const searchTermWords = searchTerm.trim().toLocaleLowerCase().split(" ").filter(Boolean);

            const query = `
              # AutoCompleteEditor
              prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
              prefix skos: <http://www.w3.org/2004/02/skos/core#>
              prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
              prefix owl: <http://www.w3.org/2002/07/owl#>
              prefix triply: <https://triplydb.com/Triply/function/>

              select
                ?value
                (coalesce(triply:firstLabel(?value), ?searchLabel) as ?label)
                ((?description_t) as ?description)
              where {
                {
                  select distinct ?value {
                    ?subType rdfs:subClassOf* ${termToString(factory.namedNode(propertyModel.type))} .
                    ?value a ?subType .
                  }
                }

                optional {
                  ?value rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm|skos:altLabel|skosxl:altLabel/skosxl:literalForm|skosxl:literalForm ?searchLabel .
                }

                ${
                  searchTermWords.length
                    ? `filter(
                  ${searchTermWords.map((searchTermWord) => `contains(lcase(?searchLabel), ${termToString(factory.literal(searchTermWord))})`).join(" && ")} ||
                  ${searchTermWords.map((searchTermWord) => `contains(lcase(str(?value)), ${termToString(factory.literal(searchTermWord))})`).join(" && ")}
                )`
                    : ""
                }

                optional {
                 ?value rdfs:comment|skos:definition ?description_t
                }
              }
              limit ${MAX_SEARCH_RESULTS + 1}
              `;

            sparql(query, abortSignal)
              .then((results) => {
                callback(results);
              })
              .catch(() => {});
          },
          400,
        ),
      [sparql, propertyModel.type],
    );

    React.useEffect(() => {
      if (!focussed) {
        return;
      }

      const abortController = new AbortController();
      let active = true;

      if (inputValue === (initialValue?.label || applyPrefixes(initialValue?.value || ""))) {
        setOptions(initialValue?.value ? [initialValue] : []);
      }
      setIsLoadingOptions(true);
      debouncedQuery({ searchTerm: inputValue, abortSignal: abortController.signal }, (results?: readonly Item[]) => {
        if (active) {
          let newOptions: readonly Item[] = [];

          if (initialValue?.value && !inputValue) {
            newOptions = [initialValue];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(uniqBy(newOptions, "value"));
          setOptionsFor(inputValue);
          setIsLoadingOptions(false);
        }
      });

      return () => {
        active = false;
        abortController.abort("Not needed anymore");
      };
    }, [initialValue, inputValue, debouncedQuery, focussed, applyPrefixes]);

    const actionsGroup = <div className="m-3" />;

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={null as any}
        render={({ field: { onChange, ref, ...rest }, fieldState: { error } }) => (
          <>
            <Autocomplete
              blurOnSelect
              className={styles.autoCompleteEditor}
              options={options}
              filterOptions={(options) => {
                const numberOfOptions = options.length;
                options = options.slice(0, MAX_SEARCH_RESULTS);
                if (numberOfOptions > MAX_SEARCH_RESULTS) {
                  options.push({
                    value: THERE_IS_MORE_KEY,
                  });
                }
                // Add create actions in the dropdowns, using value null to indicate to create a new item
                for (const action of createActions) {
                  options.push({
                    label: `${action.name}`,
                    value: null,
                  });
                }
                return options;
              }}
              groupBy={
                options.length > 0 && createActions.length > 0
                  ? (option: any) => {
                      if (!option?.value) return actionsGroup;
                    }
                  : undefined
              }
              getOptionDisabled={(option: any) => option?.value === THERE_IS_MORE_KEY}
              onChange={(_e, option: Item | null) => {
                if (option === null) {
                  onChange(null);
                  return;
                }
                if (option.value === null) {
                  setCreateNew(option.label);
                  return;
                }
                const value: IriProperty = {
                  nodeKind: "IRI",
                  value: option.value,
                  label: option.label || applyPrefixes(option.value),
                  description: option.description,
                };
                onChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as any)}
                  error={!!error}
                  helperText={
                    error?.message ||
                    (typeof rest.value !== "string" && rest.value?.nodeKind === "IRI" && rest.value.description)
                  }
                  multiline
                  required={propertyModel.required}
                  inputRef={ref}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: isLoadingOptions ? (
                      <>
                        <CircularProgress color="inherit" size={20} className={styles.loading} />
                        {params.InputProps.endAdornment}
                      </>
                    ) : (
                      params.InputProps.endAdornment
                    ),
                  }}
                />
              )}
              noOptionsText=""
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value.value;
              }}
              getOptionLabel={(option: any) => {
                return option.label || applyPrefixes(option.value) || "";
              }}
              onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onFocus={() => {
                setFocussed(true);
              }}
              getOptionKey={(option: any) => {
                // Iri of the option first, label of the create action second
                return option?.value || option?.label;
              }}
              renderOption={(props, option: Item | null) => {
                if (option?.value === THERE_IS_MORE_KEY) {
                  return (
                    <em key={THERE_IS_MORE_KEY} className="m-5">
                      <small>{`Showing the first ${MAX_SEARCH_RESULTS} results`}</small>
                    </em>
                  );
                }
                if (option?.value) {
                  const label = option.label || applyPrefixes(option.value) || "";
                  return (
                    <ListItem {...props}>
                      <Highlight fullText={label} highlightedText={optionsFor} matcher={substringMatch} />
                    </ListItem>
                  );
                }
                const createActionColor = getCreateActions().find((x) => x.name === option?.label)?.color;
                return (
                  <ListItem
                    {...props}
                    secondaryAction={
                      <Chip
                        className="ml-2"
                        size="small"
                        color={createActionColor === "default" ? undefined : createActionColor}
                        label={option?.label}
                      />
                    }
                  >
                    <i>Add new instance</i>
                  </ListItem>
                );
              }}
              {...(rest as any)}
            />
            {selectedAction && selectedAction.type === "create" && propertyModel.type && (
              <CreateEmbeddedResource
                instanceOf={propertyModel.type}
                name={selectedAction.name}
                toStatus={selectedAction.toState}
                onDone={(iri, label) => {
                  if (iri) {
                    setInputValue(iri);
                    onChange({
                      nodeKind: "IRI",
                      value: iri,
                      label: label || applyPrefixes(iri),
                    });
                  }
                  setCreateNew(undefined);
                }}
              />
            )}
          </>
        )}
      />
    );
  },
  getScore: ({ nodeKind }) => {
    if (nodeKind === "IRI") {
      return 1;
    }
    return 0;
  },
};

export default AutoCompleteEditor;
