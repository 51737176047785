import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableContainer,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import getClassName from "classnames";
import { isEmpty, upperFirst } from "lodash-es";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import { FontAwesomeIcon, MuiTextField } from "#components/index.ts";
import TableDateRenderer, { TableShortDateRenderer } from "#components/ReactTableUtils/DateRenderer.tsx";
import {
  BooleanRenderer,
  HumanizedDateInFutureRenderer,
  HumanizedDateInPastRenderer,
  HumanizedDateInPastWithoutTitleRenderer,
  SortTypes,
  TableFooter,
} from "#components/ReactTableUtils/index.ts";
import ColumnVisibility from "#components/ReactTableUtils/PersistedColumnVisibilityButton.tsx";
import ReactTableBody from "#components/ReactTableUtils/TableBody.tsx";
import TableHeader from "#components/ReactTableUtils/TableHeader.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import { impersonateTo } from "#reducers/auth.ts";
import { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";
import * as tableStyles from "#components/ReactTableUtils/tableStyle.scss";

interface Props {
  users: Models.User[];
  loading: boolean;
  error?: Error;
}

/**
 * Render components
 */

const UserLinkRenderer: React.FC<CellContext<Models.User, string>> = ({ cell }) => {
  const value = cell.getValue();
  return <Link to={`/${value}`}>{value}</Link>;
};

const AccountStatusRenderer: React.FC<CellContext<Models.User, "Disabled" | "Enabled">> = ({ cell }) => {
  const value = cell.getValue();
  if (value === "Disabled") {
    return (
      <div>
        <FontAwesomeIcon icon="ban"></FontAwesomeIcon> Disabled
      </div>
    );
  }
  return null;
};

const TableActionRenderer: React.FC<CellContext<Models.User, string>> = ({ row }) => {
  const dispatch = useDispatch();
  const acl = useAcl();
  return (
    <div className={styles.actions}>
      {row.original.type === "user" &&
        row.original.accountName !== acl.ctx.accountName &&
        acl.check({ action: "impersonateUser" }).granted && (
          <IconButton
            size="small"
            aria-label={`Impersonate ${row.original.accountName}`}
            title={`Impersonate ${row.original.accountName}`}
            onClick={() => {
              dispatch(impersonateTo(row.original.accountName));
            }}
          >
            <FontAwesomeIcon fixedWidth icon={["fas", "user-secret"]} />
          </IconButton>
        )}
      <IconButton
        size="small"
        title={`Go to user settings`}
        aria-label={`Go to user settings`}
        href={`/${row.original.accountName}/-/settings`}
        LinkComponent={React.forwardRef(({ href, ...props }, _ref) => (
          <Link {...props} to={href} />
        ))}
      >
        <FontAwesomeIcon fixedWidth icon={["fas", "cog"]} />
      </IconButton>
    </div>
  );
};

/**
 * Column Config
 */
function getColumns(showLegalConsent: boolean): ColumnDef<Models.User, any>[] {
  const cols: Array<ColumnDef<Models.User, any> | undefined> = [
    {
      header: "Name",
      accessorKey: "accountName",
      cell: UserLinkRenderer,
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Display name",
      accessorFn: (account) => account.name || "",
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Email",
      accessorFn: (account) => account.email || "",
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Created at",
      accessorKey: "createdAt",
      cell: HumanizedDateInPastRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Updated at",
      accessorKey: "updatedAt",
      cell: HumanizedDateInPastRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Last activity",
      accessorKey: "lastActivity",
      cell: HumanizedDateInPastWithoutTitleRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Expires at",
      accessorKey: "expiresAt",
      cell: HumanizedDateInFutureRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Role",
      accessorKey: "role",
      filterFn: "equals",
    },
    {
      header: "Verified",
      accessorKey: "verified",
      cell: BooleanRenderer,
      sortingFn: "boolean" as any, // Needs to be the same name as the function assigned in the useReactTable hook,
      filterFn: "equals",
    },
    {
      header: "Account state",
      // For the equals filter we need to use a "placeholder" for undefined as an empty value means "all"
      accessorFn: (a) => (a.disabled ? "Disabled" : "Enabled"),
      cell: AccountStatusRenderer,
      filterFn: "equals",
      id: "account-state",
    },
    {
      header: "Authentication method",
      accessorKey: "authMethod",
      filterFn: "equals",
    },
    {
      header: "2FA enabled",
      accessorKey: "mfaEnabled",
      cell: BooleanRenderer,
      sortingFn: "boolean" as any, // Needs to be the same name as the function assigned in the useReactTable hook,
      filterFn: "equals",
    },
    showLegalConsent
      ? {
          header: "Legal consent",
          // For the equals filter we need to use a "placeholder" for undefined as an empty value means "all"
          accessorFn: (a) => (a.legalConsent === "unset" ? "n/a" : a.legalConsent),
          filterFn: "equals",
        }
      : undefined,
    {
      id: "actions",
      header: "",
      cell: TableActionRenderer,
    },
  ];
  return cols.filter((col) => col !== undefined) as ColumnDef<Models.User, any>[];
}

const defaultColumn: Partial<ColumnDef<Models.User, any>> = {
  cell: (props) => {
    const val = props.getValue();
    if (val === undefined || val === "n/a") return null;
    return <span>{val}</span>;
  },
};

const UsersTable: React.FC<Props> = ({ users, loading, error }) => {
  const shouldShowLegalConsent = useSelector((state: GlobalState) => !isEmpty(state.config.clientConfig?.legal));
  const columns = React.useMemo(() => getColumns(shouldShowLegalConsent), [shouldShowLegalConsent]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    {
      id: "account-state",
      value: "Enabled",
    },
  ]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const debouncedSetGlobalFilter = useDebounce(setGlobalFilter, 200);
  const [localStorage, setLocalStorage] = useLocalStorage("table-users", "{}");
  const ignoreColumnVisibilty = ["accountName", "actions"];
  const table = useReactTable<Models.User>({
    columns: columns,
    data: users,
    state: {
      columnFilters,
      globalFilter,
      columnVisibility: JSON.parse(localStorage),
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
      columnFilters: [
        {
          id: "account-state",
          value: "Enabled",
        },
      ],
    },
    defaultColumn: defaultColumn,
    sortingFns: SortTypes,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (account) => account.uid,
  });
  return (
    <Paper square className={tableStyles.tablePaper}>
      <Toolbar className={getClassName("mb-3 g-7 flex", tableStyles.alignEnd)}>
        <Typography variant="h4">Users</Typography>
        <Link to={{ state: { accountAddModalShown: true } }}>
          <IconButton title="Add user" aria-label="Add user" size="large">
            <FontAwesomeIcon icon="user-plus" />
          </IconButton>
        </Link>
        <Link to={{ state: { accountAddBulkModalShown: true } }}>
          <IconButton title="Add users in bulk" aria-label="Add users in bulk" size="large">
            <FontAwesomeIcon icon="users-medical" />
          </IconButton>
        </Link>
        <ColumnVisibility
          columns={table.getAllLeafColumns()}
          ignoreVisibility={ignoreColumnVisibilty}
          onChange={(visibility) => setLocalStorage(JSON.stringify(visibility))}
        />
        <div className={tableStyles.space} />
        <MuiTextField
          label="Search"
          onChange={(e) => debouncedSetGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon="search" />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <TableContainer className={tableStyles.tableContainer}>
        <div className="px-5">
          <Table size="small">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <ReactTableBody
              rows={table.getRowModel().rows}
              loading={loading}
              error={error?.message}
              columnCount={columns.length}
            />
            <TableFooter
              currentPage={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              rowCount={table.getPrePaginationRowModel().rows.length}
              onChangePage={table.setPageIndex}
              onChangeRowsPerPage={table.setPageSize}
            />
          </Table>
        </div>
      </TableContainer>
    </Paper>
  );
};
export default UsersTable;
