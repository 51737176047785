import { autocompletion, closeBrackets } from "@codemirror/autocomplete";
import { history, indentLess } from "@codemirror/commands";
import { bracketMatching, foldGutter, indentOnInput } from "@codemirror/language";
import { linter, lintGutter } from "@codemirror/lint";
import { highlightSelectionMatches } from "@codemirror/search";
import { Compartment, EditorState, Facet } from "@codemirror/state";
import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLineGutter,
  keymap,
  lineNumbers,
  rectangularSelection,
} from "@codemirror/view";
import { IconButton } from "@mui/material";
import { vscodeKeymap } from "@replit/codemirror-vscode-keymap";
import { githubLight } from "@uiw/codemirror-theme-github";
import getClassName from "classnames";
import { turtle, TurtleLanguage } from "codemirror-lang-turtle";
import * as React from "react";
import { Prefix } from "@triply/utils";
import { definedPrefixesField } from "#components/Sparql/Editor//fields/definedPrefixes.ts";
import SparqlPrefixAutocomplete from "#components/Sparql/Editor/autocompleters/prefixAutoComplete.ts";
import { autoCloseIri } from "#components/Sparql/Editor/commands/autoCloseIri.ts";
import autoFormat from "#components/Sparql/Editor/commands/autoFormat.ts";
import { injectPrefix } from "#components/Sparql/Editor/commands/injectPrefix.ts";
import { insertSpaces } from "#components/Sparql/Editor/commands/tabsInsertSpace.ts";
import { OutsidePrefixes } from "#components/Sparql/Editor/facets/outsidePrefixes.ts";
import PrefixLinter from "#components/Sparql/Editor/linters/PrefixLinter.ts";
import syntaxLinter from "./syntaxLinter";
import * as styles from "../../SparqlIde/styles.scss";

export interface Props {
  serializedString?: string;
  prefixes?: Prefix[];
  onChange?: (values: { updatedString: string }) => void;
  className?: string;
}

const QuadEditor: React.FC<Props> = ({ serializedString, prefixes, onChange, className }) => {
  const serializedValue = React.useRef<string | undefined>(serializedString);
  const valueOnChange = React.useRef(onChange);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const viewRef = React.useRef<EditorView | null>(null);
  const changeListener = React.useRef(new Compartment());
  const outsidePrefixes = React.useRef(new Compartment());
  const termAutocomplete = React.useRef(new Compartment());
  const onSubmit = React.useRef(new Compartment());

  // Mount & Cleanup hook
  React.useEffect(() => {
    if (containerRef.current) {
      const initialState = EditorState.create({
        doc: serializedValue.current,
        extensions: [
          TurtleLanguage,
          lineNumbers(),
          highlightActiveLineGutter(),
          history(),
          foldGutter(),
          drawSelection(),
          dropCursor(),
          EditorState.allowMultipleSelections.of(true),
          indentOnInput(),
          githubLight,
          bracketMatching(),
          autocompletion({ defaultKeymap: false }),
          closeBrackets(),
          rectangularSelection(),
          crosshairCursor(),
          lintGutter(),
          highlightSelectionMatches(),
          turtle(),
          // linter(syntaxLinter),
          linter(PrefixLinter),
          definedPrefixesField,
          TurtleLanguage.data.from(definedPrefixesField),
          TurtleLanguage.data.of({
            autocomplete: SparqlPrefixAutocomplete,
          }),
          outsidePrefixes.current.of(OutsidePrefixes.of([])),
          termAutocomplete.current.of(TurtleLanguage.data.of(() => {})),
          keymap.of(vscodeKeymap.filter((setting) => !(setting.key === "Tab" && setting?.preventDefault))),
          keymap.of([
            {
              key: "Mod-i",
              shift: autoFormat,
              preventDefault: true,
            },
            {
              key: "Tab",
              run: insertSpaces,
              shift: indentLess,
              preventDefault: true,
            },
            {
              key: "<",
              run: autoCloseIri,
              preventDefault: false,
            },
            {
              key: ":",
              run: injectPrefix,
            },
          ]),
          changeListener.current.of(EditorView.updateListener.of(() => {})),
          onSubmit.current.of(keymap.of([])),
        ].filter((extension) => !!extension),
      });
      viewRef.current = new EditorView({
        state: initialState,
        parent: containerRef.current,
      });

      return () => {
        viewRef.current?.destroy();
        viewRef.current = null;
      };
    }
  }, []);

  // Update onChange listener
  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: [outsidePrefixes.current.reconfigure(OutsidePrefixes.of(prefixes || []))],
    });
  }, [prefixes]);

  React.useEffect(() => {
    viewRef.current?.dispatch({
      effects: changeListener.current.reconfigure(
        // Contrary to the docs, this reassigns a function, not register a new one
        EditorView.updateListener.of(
          onChange
            ? (update) => {
                if (update.docChanged) {
                  onChange?.({
                    updatedString: update.state.doc.toString(),
                  });
                }
              }
            : () => {},
        ),
      ),
    });
  }, [onChange]);

  return (
    <>
      <div ref={containerRef} className={getClassName(className, styles.editor)} style={{ height: "inherit" }} />
    </>
  );
};

export default QuadEditor;
