import { syntaxTree, TreeIndentContext } from "@codemirror/language";
import Debug from "debug";
import { node } from "prop-types";
import { PropertyListNotEmpty, PropertyListPathNotEmpty } from "./sparqlParser.terms";

const debug = Debug("triply:sparql-editor:indent");

//General rule: we use context.column when we care about the position of the previous item and
// context.unit for standardized indentation.

/**
 * Indents lines of the token at the same column as the start of the token.
 */
export function sameColumn(conf: { units: number }) {
  return (context: TreeIndentContext) => {
    debug("sameColumn");
    return context.column(context.node.from) + context.unit * conf.units;
  };
}

/**
 * No indent for these tokens.
 */
export function noIndent(context: TreeIndentContext) {
  debug("noIndent");
  return null;
}

/**
 * Indents lines of propertylist tokens:
 */
export function indentPropertyList(context: TreeIndentContext): number {
  debug("indentPropertyList");
  // Node is always root of the block we're trying to indent
  const { node } = context;

  // When simulating an enter, we can't rely on the complete state, we we need to walk back with the cursor
  if (context.simulatedBreak) {
    const cursor = context.node.toTree().cursorAt(context.pos);
    while (cursor.prev()) {
      if (cursor.node.name === ".") {
        break;
      }
      // The list break was a ; meaning this should be a
      if (
        cursor.node.name === ";" &&
        (cursor.node.parent?.type.id === PropertyListPathNotEmpty ||
          cursor.node.parent?.type.id === PropertyListNotEmpty)
      ) {
        return context.column(context.node.from + cursor.node.parent.from);
      }
    }
  }
  // Grab the first child-node on this line in this node
  const firstNodeOfLine = node.childAfter(context.pos);

  // When the first item is a ObjectList or there is no item yet, we want it to indent with an extra unit
  if (firstNodeOfLine?.name === "ObjectListPath" || firstNodeOfLine?.name === "ObjectList" || !firstNodeOfLine) {
    return context.column(node.from) + context.unit;
  }
  // Otherwise we always want to indent at the same height as the propertyList starts
  return context.column(context.node.from);
}

/**
 * Group graph patterns need special indentation rules as this is the working node for an "incomplete" query
 */
export function indentGroupGraphPattern(context: TreeIndentContext) {
  debug("indentGroupGraphPattern");
  if (context.textAfter.trim().startsWith("}")) {
    return context.baseIndent;
  }
  const cursor = context.node.toTree().cursor(context.pos);

  if (context.simulatedBreak) {
    // SimulatedBreak means a linebreak is being injected
    // When defining a property path, after the ; we're back in the GroupGraphPattern, we should "walk back" until we find a line end, as we can encounter both property lists or new triples
    const cursor = context.node.toTree().cursorAt(context.pos);
    while (cursor.prev()) {
      if (cursor.node.name === ".") {
        break;
      }
      // The list break was a ; meaning this should be a
      if (
        cursor.node.name === ";" &&
        (cursor.node.parent?.type.id === PropertyListPathNotEmpty ||
          cursor.node.parent?.type.id === PropertyListNotEmpty)
      ) {
        return context.column(context.node.from + cursor.node.parent.from);
      }
    }
  }

  const nodeAtCursor = syntaxTree(context.state)!.resolve(context.pos);

  if (nodeAtCursor.name === "Bind" || nodeAtCursor.name === "BuiltInCall") return context.baseIndent + context.unit;

  //when {?s| ?p ?o.}
  if (nodeAtCursor.name === "TriplesSameSubjectPath") return context.baseIndent + 2 * context.unit;

  if (cursor.node.name === "GroupGraphPattern" && nodeAtCursor.name === "Program") {
    // when ?s ?p ?o.|
    if (context.textAfterPos(context.pos - 1) === ".") return context.baseIndent;
    // when ?s|
    if (context.node.getChild("GroupGraphPatternSub")) return context.baseIndent + 2 * context.unit;
  }
  return context.baseIndent + context.unit;
}

/**
 * Construct template patterns need special indentation rules as this is the working node for an "incomplete" query
 */
export function indentConstructTemplatePattern(context: TreeIndentContext) {
  //for the closing bracket of the construct template
  if (context.textAfter.trim().startsWith("}")) {
    return context.baseIndent;
  }

  debug("constructTemplate");
  if (context.simulatedBreak) {
    // SimulatedBreak means a linebreak is being injected
    // When defining a property path, after the ; we're back in the GroupGraphPattern.
    const cursor = context.node.toTree().cursorAt(context.pos);
    while (cursor.prev()) {
      if (cursor.node.name === ".") {
        break;
      }
      if (cursor.node.name === ";" && cursor.node.parent?.type.id === PropertyListNotEmpty) {
        return context.column(context.node.from + cursor.node.parent.from);
      }
    }
  }
  const nodeAtCursor = syntaxTree(context.state)!.resolve(context.pos);
  if (nodeAtCursor.name === "TriplesSameSubject") return context.baseIndent + context.unit * 2;
  return context.baseIndent + context.unit;
}
