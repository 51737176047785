import { generateColor } from "@marko19907/string-to-color";
import { Autocomplete, Chip, CircularProgress, ListItem, TextField } from "@mui/material";
import { debounce } from "@mui/material/utils";
import * as React from "react";
import { useHistory } from "react-router";
import { getLocalNameInfo } from "@triply/utils/prefixUtils.js";
import { substringMatch } from "#components/Highlight/index.tsx";
import { Highlight } from "#components/index.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { setLastDataEditorResource } from "#reducers/datasets.ts";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import { stringifyQuery } from "../../../helpers/utils";
import { useCurrentDataset } from "../../../reducers/datasetManagement";
import { MAX_SEARCH_RESULTS } from "..";
import { expandedContext } from "./tree/ExpandedContext";
import { sparqlSearch } from "./tree/queries";
import { COLOR_GENERATE_OPTIONS } from "./SkosTree";
import * as styles from "./style.scss";

const THERE_IS_MORE_KEY = "_";

export interface ConceptSchemeData {
  conceptScheme: string;
  conceptSchemeLabel: string;
}

interface Concept {
  id: string;
  label: string;
  scheme: string;
  schemeLabel?: string;
}

export const SearchField: React.FC<{ selectedSchemes: string[] }> = ({ selectedSchemes }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedConcept, setSelectedConcept] = React.useState<Concept | null>(null);
  const [options, setOptions] = React.useState<readonly Concept[]>([]);
  const [optionsFor, setOptionsFor] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const search = useCurrentSearch();
  const dispatch = useDispatch();
  const { collapseAll } = React.useContext(expandedContext);

  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const { accountName } = currentDs.owner;
  const datasetName = currentDs.name;

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/json" },
        body: new URLSearchParams({
          account: accountName,
          dataset: datasetName,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    [sparqlUrl, accountName, datasetName],
  );

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly Concept[]) => void,
        ) => {
          setLoading(true);
          sparql(sparqlSearch(searchTerm, selectedSchemes), abortSignal)
            .then(callback)
            .catch(() => {})
            .finally(() => setLoading(false));
        },
        200,
      ),
    [sparql, selectedSchemes],
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;

    if (searchTerm === "") {
      setSelectedConcept(null);
      setOptions([]);
      return;
    }

    debouncedQuery({ searchTerm: searchTerm, abortSignal: abortController.signal }, (results?: readonly Concept[]) => {
      if (active) {
        setOptions(results?.length ? results : []);
        setOptionsFor(searchTerm);
        if (!results?.length) setSelectedConcept(null);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [searchTerm, debouncedQuery]);

  const history = useHistory();

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
      options={
        options.length > MAX_SEARCH_RESULTS
          ? [...options.slice(0, MAX_SEARCH_RESULTS), { id: THERE_IS_MORE_KEY } as Concept]
          : options
      }
      getOptionDisabled={(option) => option.id === THERE_IS_MORE_KEY}
      clearIcon={false}
      filterOptions={(options) => options}
      fullWidth
      value={selectedConcept}
      noOptionsText="No results"
      getOptionKey={(option) => option.id}
      onChange={(_event: any, newValue: Concept | null, reason) => {
        if (reason === "selectOption") {
          if (newValue?.id) {
            setSelectedConcept(newValue);
            dispatch(setLastDataEditorResource(currentDs.id, newValue.id));
            collapseAll();
            history.push({
              search: stringifyQuery({ ...search, resource: newValue.id }),
            });
          }

          setSearchTerm("");
        } else {
          setSelectedConcept(null);
        }
      }}
      renderOption={(props, option) => {
        if (option.id === THERE_IS_MORE_KEY) {
          return (
            <em key={THERE_IS_MORE_KEY} className="m-4">
              <small>{`Showing the first ${MAX_SEARCH_RESULTS} results`}</small>
            </em>
          );
        }
        const schemeColor = generateColor(option.scheme, COLOR_GENERATE_OPTIONS);
        return (
          <ListItem
            {...props}
            secondaryAction={
              <Chip
                style={{ background: schemeColor }}
                label={option.schemeLabel || getLocalNameInfo(option.scheme).localName}
                size="small"
              />
            }
          >
            <Highlight
              fullText={option.label}
              className={styles.skosSearchFieldOptionText}
              highlightedText={optionsFor}
              matcher={substringMatch}
            />
          </ListItem>
        );
      }}
      onInputChange={(_event, newSearchTerm) => setSearchTerm(newSearchTerm)}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search in hierarchy"
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
            }}
          />
        );
      }}
    />
  );
};
