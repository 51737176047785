import { IconButton, InputAdornment, Paper, Table, TableContainer, Toolbar, Typography } from "@mui/material";
import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import { FontAwesomeIcon, MuiTextField } from "#components/index.ts";
import { SortTypes, TableFooter } from "#components/ReactTableUtils/index.ts";
import ColumnVisibility from "#components/ReactTableUtils/PersistedColumnVisibilityButton.tsx";
import ReactTableBody from "#components/ReactTableUtils/TableBody.tsx";
import TableHeader from "#components/ReactTableUtils/TableHeader.tsx";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import HumanizedDateInPast from "../../../components/ReactTableUtils/HumanizedDateInPastRenderer";
import * as styles from "./style.scss";
import * as tableStyles from "#components/ReactTableUtils/tableStyle.scss";

interface Props {
  orgs: Models.Org[];
  loading?: boolean;
  error?: Error;
}

/**
 * Render components
 */
const OrgLinkRenderer: React.FC<CellContext<Models.Org, string>> = ({ cell }) => {
  const value = cell.getValue();
  return <Link to={`/${value}`}>{value}</Link>;
};

const TableActionRenderer: React.FC<CellContext<Models.Org, string>> = ({ row }) => {
  return (
    <div className={styles.actions}>
      <IconButton
        size="small"
        title={`Go to organization settings`}
        aria-label={`Go to organization settings`}
        href={`/${row.original.accountName}/-/settings`}
        LinkComponent={React.forwardRef(({ href, ...props }, _ref) => (
          <Link {...props} to={href} />
        ))}
      >
        <FontAwesomeIcon fixedWidth icon={["fas", "cog"]} />
      </IconButton>
    </div>
  );
};

/**
 * Column Config
 */
function getColumns(): ColumnDef<Models.Org, any>[] {
  const cols: Array<ColumnDef<Models.Org, any> | undefined> = [
    {
      header: "Name",
      accessorKey: "accountName",
      cell: OrgLinkRenderer,
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },

    {
      header: "Display name",
      accessorFn: (account) => account.name || "",
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Email",
      accessorFn: (account) => account.email || "",
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Created at",
      accessorKey: "createdAt",
      cell: HumanizedDateInPast,
      enableColumnFilter: false,
    },
    {
      header: "Updated at",
      accessorKey: "updatedAt",
      cell: HumanizedDateInPast,
      enableColumnFilter: false,
    },
    {
      id: "actions",
      header: "",
      cell: TableActionRenderer,
    },
  ];
  return cols.filter((col) => col !== undefined) as ColumnDef<Models.Org, any>[];
}

const defaultColumn: Partial<ColumnDef<Models.Org, any>> = {
  cell: (props) => {
    const val = props.getValue();
    if (val === undefined || val === "n/a") return null;
    return <span>{val}</span>;
  },
};

const OrgsTable: React.FC<Props> = ({ orgs, loading, error }) => {
  const columns = React.useMemo(() => getColumns(), []);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const debouncedSetGlobalFilter = useDebounce(setGlobalFilter, 200);
  const [localStorage, setLocalStorage] = useLocalStorage("table-orgs", "{}");
  const ignoreColumnVisibilty = ["accountName", "actions"];

  const table = useReactTable<Models.Org>({
    columns: columns,
    data: orgs || [],
    state: {
      columnFilters,
      globalFilter,
      columnVisibility: JSON.parse(localStorage),
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    defaultColumn: defaultColumn,
    sortingFns: SortTypes,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (account) => account.uid,
  });
  return (
    <Paper square className={tableStyles.tablePaper}>
      <Toolbar className={getClassName("mb-3 g-7 flex", tableStyles.alignEnd)}>
        <Typography variant="h4">Organizations</Typography>
        <ColumnVisibility
          columns={table.getAllLeafColumns()}
          ignoreVisibility={ignoreColumnVisibilty}
          onChange={(visibility) => setLocalStorage(JSON.stringify(visibility))}
        />
        <div className={tableStyles.space} />
        <MuiTextField
          label="Search"
          onChange={(e) => debouncedSetGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon="search" />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <TableContainer className={tableStyles.tableContainer}>
        <div className="px-5">
          <Table size="small">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <ReactTableBody
              rows={table.getRowModel().rows}
              loading={loading}
              error={error?.message}
              columnCount={columns.length}
            />
            <TableFooter
              currentPage={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              rowCount={table.getPrePaginationRowModel().rows.length}
              onChangePage={table.setPageIndex}
              onChangeRowsPerPage={table.setPageSize}
            />
          </Table>
        </div>
      </TableContainer>
    </Paper>
  );
};
export default OrgsTable;
