import {
  Alert,
  Autocomplete,
  FormHelperText,
  MenuItem,
  Skeleton,
  TextField,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { cloneDeep, forEach, merge } from "lodash-es";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { substringMatch } from "#components/Highlight/index.tsx";
import { FormField, Highlight, Prompt } from "#components/index.ts";
import { validateIri } from "#containers/DataModel/Forms/helpers.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { SparqlBasedConstraintError } from "./executeSparqlBasedConstraints";
import NodeShape from "./NodeShape";
import localTheme from "./Theme";
import { FormValues, Property } from "./Types";
import useClasses from "./useClasses";
import useFetchInitialValues from "./useFetchInitialValues";
import * as styles from "./style.scss";

const InstanceForm: React.FC<{
  onSubmit: (values: FormValues, initialValues?: FormValues) => Promise<void>;
  onDirty: (dirty: boolean) => void;
  isCopy?: boolean;
  editingResource?: string;
  instanceOf?: string;
}> = ({ onSubmit, onDirty, isCopy, editingResource, instanceOf }) => {
  const theme = useTheme();
  const fetchInitialValues = useFetchInitialValues();

  const applyPrefixes = useApplyPrefixes();
  const currentDs = useCurrentDataset()!;
  const consoleUrl = useConstructConsoleUrl()();
  const datasetUrl = `${consoleUrl}/${currentDs.owner.accountName}/${currentDs.name}`;
  const removePrefixes = useRemovePrefixes();
  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "id")?.iri || `${datasetUrl}/id/`;

  const methods = useForm<FormValues>({
    shouldUnregister: false,
    defaultValues:
      (editingResource &&
        (async () => {
          let values = await fetchInitialValues(editingResource);
          if (isCopy) {
            forEach(values.properties, (property, key) => {
              values.properties[key] = property.map((p) => {
                if (p && p.nodeKind === "NestedNode") {
                  return { ...p, value: `${consoleUrl}/.well-known/genid/i-${uuid()}` };
                } else {
                  return p;
                }
              });
            });
            return values;
          } else {
            return values;
          }
        })) ||
      undefined,
  });

  const values = methods.watch();
  const type = methods.watch("type.id");
  const classes = useClasses(instanceOf);
  const location = useLocation();

  React.useEffect(() => {
    onDirty?.(methods.formState.isDirty);
  }, [methods.formState.isDirty, onDirty]);

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isDirty, defaultValues },
  } = methods;

  const submitEnabled = isCopy || isDirty;

  const submitWithoutPropagation = React.useCallback(
    (e: React.FormEvent) => {
      e.stopPropagation();
      e.preventDefault();
      return handleSubmit(async (values: FormValues) => {
        try {
          // Retrieve initial values again, as when fields are removed from the form, the original values are deleted
          await onSubmit(values, editingResource ? await fetchInitialValues(editingResource) : undefined);
        } catch (e) {
          if (e instanceof SparqlBasedConstraintError) {
            setError("root", {
              type: "400",
              message: e.message,
            });

            return;
          }

          console.error(e);
          setError("root.serverError", {
            type: "500",
          });
        }
      })(e);
    },
    [handleSubmit, onSubmit, editingResource, fetchInitialValues, setError],
  );

  if (!classes) {
    return <Skeleton variant="rectangular" width={860} height={175} />;
  }
  if (classes.length === 0) {
    return (
      <Alert severity="info">
        No SHACL shapes were found {editingResource ? "for this resource" : "in this dataset"}
      </Alert>
    );
  }

  return (
    <ThemeProvider theme={merge(cloneDeep(localTheme), theme)}>
      <Prompt
        when={isDirty}
        message={(newState) => {
          //dont want the prompt to show up when only changing the location state
          //Otherwise, drawing the modal would trigger it
          if (location.pathname === newState.pathname) return true;
          return "Your changes have not been saved. Are you sure you want to continue?";
        }}
      />

      <FormProvider {...methods}>
        <form method="POST" onSubmit={submitWithoutPropagation} className="flex column g-7">
          <FormField className={styles.formField} label="Type" required>
            <Controller
              name="type"
              control={control}
              defaultValue={null}
              rules={{ required: "A type is required." }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <Autocomplete
                  options={classes}
                  onChange={(_e, data: any) => {
                    // When a class is selected we reset the value for the IRI to take dash:stem into account.
                    setValue("iri", data?.stem ? `${data.stem}i-${uuid()}` : applyPrefixes(`${baseIri}i-${uuid()}`));
                    onChange(data);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...(params as any)}
                      required
                      error={!!error}
                      helperText={error?.message || rest.value?.description}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return option.label || applyPrefixes(option.id);
                  }}
                  getOptionKey={(option) => option.id}
                  renderOption={(props, option, { inputValue }) => {
                    const label = option.label || applyPrefixes(option.id) || "";
                    return (
                      <MenuItem {...props}>
                        <Highlight fullText={label} highlightedText={inputValue} matcher={substringMatch} />
                      </MenuItem>
                    );
                  }}
                  {...rest}
                />
              )}
            />
          </FormField>

          {(isCopy || !editingResource) && (
            <FormField className={styles.formField} label="Instance IRI" required>
              <Controller
                name="iri"
                control={control}
                rules={{
                  validate: (value) => validateIri(removePrefixes(value?.trim())),
                }}
                defaultValue={
                  values.type?.stem ? `${values.type.stem}i-${uuid()}` : applyPrefixes(`${baseIri}i-${uuid()}`)
                }
                render={({ field, fieldState }) => (
                  <>
                    {values.type?.stem ? <FormHelperText>{applyPrefixes(values.type.stem)}</FormHelperText> : null}
                    <TextField
                      {...field}
                      value={values.type?.stem ? field.value.replace(values.type.stem, "") : field.value}
                      onChange={(event) => {
                        field.onChange({
                          ...event,
                          target: {
                            ...event.target,
                            value: values.type?.stem ? `${values.type.stem}${event.target.value}` : event.target.value,
                          },
                        });
                      }}
                      error={!!fieldState.error}
                      required
                      helperText={fieldState.error?.message}
                    />
                  </>
                )}
              />
            </FormField>
          )}

          <NodeShape classIri={type} namePrefix="properties" />

          {errors.root && (
            <Alert severity="error">{errors.root?.message ?? `Something went wrong on the server...`}</Alert>
          )}

          <LoadingButton color="secondary" type="submit" loading={isSubmitting} disabled={!submitEnabled}>
            Save
          </LoadingButton>
        </form>
      </FormProvider>
    </ThemeProvider>
  );
};

export default InstanceForm;
