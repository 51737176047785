import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import urlParse from "url-parse";
import { NtriplyTerm } from "@triply/utils/Models.js";
import FallbackRenderer from "#components/Fallbackrenderer/index.tsx";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import Image from "#components/Image/index.tsx";
import { Leaflet } from "#components/index.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { StaticConfig } from "#reducers/config.ts";
import { GlobalState } from "#reducers/index.ts";
import { getLabelFromLocalName, WidgetCollection, WidgetConfig } from "#reducers/resourceDescriptions.ts";
import * as styles from "./style.scss";

export namespace ResourceWidget {
  export interface Props {
    resource: NtriplyTerm;
    widgetCollection: WidgetCollection;
    loadResource?: (resource: string) => void;
    linkPath: string;
    className?: string;
    omitLabel?: boolean;
    fallbackElement?: React.ReactElement;
  }
  export interface State {}
}
function fromImageProxyIfNeeded(url: string, staticConfig: StaticConfig, imageProxy: string | undefined) {
  if (
    staticConfig?.apiUrl &&
    staticConfig?.consoleUrl &&
    imageProxy &&
    [staticConfig.apiUrl.domain, staticConfig.consoleUrl.domain].indexOf(urlParse(url).hostname) === -1
  ) {
    return imageProxy + "/120x120/" + url;
  }
  return url;
}

class ResourceWidget extends React.PureComponent<ResourceWidget.Props, ResourceWidget.State> {
  state: ResourceWidget.State = {};
  dragHandler = (e: React.DragEvent<HTMLAnchorElement>) =>
    e.dataTransfer.setData("text/plain", this.props.resource.value);

  render() {
    const { resource, widgetCollection, loadResource, linkPath, className, omitLabel, fallbackElement } = this.props;

    if (!widgetCollection) return null;

    const label = !omitLabel
      ? widgetCollection.label?.values?.[0].getTerm().value || getLabelFromLocalName(resource.value)
      : undefined;

    const imageWidget =
      (widgetCollection.image?.values?.[0] && <ImageWidget label={label} widgetConfig={widgetCollection.image} />) ||
      null;
    const geoWidget =
      (widgetCollection.geometry && <GeoWidget label={label} widgetConfig={widgetCollection.geometry} />) || null;
    const audioWidget =
      (widgetCollection.audio && <AudioWidget label={label} widgetConfig={widgetCollection.audio} />) || null;
    const videoWidget =
      (widgetCollection.video && <VideoWidget label={label} widgetConfig={widgetCollection.video} />) || null;
    const baseWidget = fallbackElement ?? <DefaultWidget label={label} termValue={resource.value} />;
    const fallbackWidgets = [imageWidget, geoWidget, videoWidget, audioWidget, baseWidget];
    return (
      <Link
        onClick={
          loadResource
            ? (e) => {
                e.preventDefault();
                loadResource(resource.value);
              }
            : undefined
        }
        to={{ pathname: linkPath, search: stringifyQuery({ resource: resource.value }) }}
        className={getClassName({ [styles.link]: !!styles.link, [className || ""]: !!className })}
        draggable
        onDragStart={this.dragHandler}
        title={label}
      >
        <FallbackRenderer fallBackElements={fallbackWidgets} />
      </Link>
    );
  }
}
export default ResourceWidget;

interface WidgetProps {
  label?: string;
  widgetConfig: WidgetConfig;
}
interface DefaultWidgetProps {
  label?: string;
  termValue: string;
}
const DefaultWidget: React.FC<DefaultWidgetProps> = ({ label, termValue }) => <>{label || termValue}</>;
const ImageWidget: React.FC<WidgetProps> = ({ label, widgetConfig }) => {
  const staticConfig = useSelector((state: GlobalState) => state.config.staticConfig);
  if (!staticConfig) throw new Error("No config");
  const imageProxy = useSelector((state: GlobalState) => state.config.values?.imageProxy);
  if (!widgetConfig.values?.[0]) throw new Error("No image found");
  return (
    <div className={getClassName("rounding", styles.imageWidget)}>
      <Image
        imageClassName={styles.imageWidgetImage}
        src={`${fromImageProxyIfNeeded(widgetConfig.values[0].getTerm().value, staticConfig, imageProxy)}`}
        alt=""
        fallback={<FontAwesomeIcon icon="image" className="pt-3 px-5" size="5x" />}
      />
      {label && <div className={styles.caption}>{label}</div>}
    </div>
  );
};
const GeoWidget: React.FC<WidgetProps> = ({ label, widgetConfig }) => {
  return (
    <div className={getClassName("rounding", styles.geoWidget)}>
      {widgetConfig.config?.type === "latLong" && widgetConfig.values && (
        <Leaflet
          lat={widgetConfig.values[0].getTerm().value}
          long={widgetConfig.values[1].getTerm().value}
          noControls
          width={120}
          height={120}
        />
      )}
      {widgetConfig.config?.type === "wkt" && widgetConfig.values && (
        <Leaflet wkt={widgetConfig.values[0].getTerm().value} noControls width={120} height={120} />
      )}
      {label && <div className={styles.caption}>{label}</div>}
    </div>
  );
};
const AudioWidget: React.FC<WidgetProps> = ({ label }) => {
  return (
    <div className={getClassName("rounding", styles.mediaWidget)}>
      <FontAwesomeIcon icon="music" className="pt-3 px-5" size="5x" />
      {label && <div className={styles.caption}>{label}</div>}
    </div>
  );
};
const VideoWidget: React.FC<WidgetProps> = ({ label }) => {
  return (
    // Just display a placeholder, prefer image over the placeholders
    <div className={getClassName("rounding", styles.mediaWidget)}>
      <FontAwesomeIcon icon="video" className="pt-3 px-5" size="5x" />
      {label && <div className={styles.caption}>{label}</div>}
    </div>
  );
};
