import { createContext, ReactNode, useContext, useEffect } from "react";
import * as React from "react";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";

const cachedSparqlContext = createContext(new Map());

export function CachedSparqlContext({ children }: { children: ReactNode }) {
  const cache = React.useRef(new Map());
  const currentDs = useCurrentDataset();
  const lastUpdate = currentDs?.lastGraphsUpdateTime;

  useEffect(() => {
    const cacheReference = cache.current;

    return () => {
      cacheReference.clear();
    };
  }, [lastUpdate]);

  return <cachedSparqlContext.Provider value={cache.current}>{children}</cachedSparqlContext.Provider>;
}

export function useCachedSparql<T>(query: string | false): Omit<ReturnType<typeof useSparql<T>>, "resultsFor"> {
  const cache = useContext(cachedSparqlContext);
  const currentDs = useCurrentDataset();
  const lastUpdate = currentDs?.lastGraphsUpdateTime;
  const fingerPrint = `${query}${lastUpdate}`;

  const { data: freshData, loading, error, resultsFor } = useSparql<T>(cache.has(fingerPrint) ? false : query);

  useEffect(() => {
    if (!!query && !cache.has(fingerPrint) && freshData && resultsFor === query) {
      cache.set(fingerPrint, freshData);
    }
    // This shouldn't trigger when the fingerPrint changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freshData, cache, query, resultsFor]);

  return { data: (cache.has(fingerPrint) ? cache.get(fingerPrint) : freshData) as T, loading, error };
}
