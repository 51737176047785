import { Container, Paper } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { getLocalNameInfo } from "@triply/utils/prefixUtils.js";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import Abstract from "./Abstract";
import ClassBreadcrumbs from "./ClassBreadcrumbs";
import ClassInfo from "./ClassInfo";
import Description from "./Description";
import Header from "./Header";
import Instances from "./Instances";
import Iri from "./Iri";
import Links from "./Links";
import * as styles from "./styles/index.scss";

const factory = factories.compliant;

const Class: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();
  const { data, error, loading } = useSparql<[{ label: string }?]>(
    currentClass &&
      `
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix triply: <https://triplydb.com/Triply/function/>

    select ?label where {
      bind(${termToString(factory.namedNode(currentClass))} as ?currentClass)
      bind(triply:firstLabel(?currentClass) as ?label)
    } limit 1
  `,
  );

  return (
    <Paper className={getClassName("p-5", styles.classPaper)}>
      {!!currentClass && (
        <Container className={getClassName("flex column g-5", styles.classContainer)}>
          <div>
            <Header label={data?.[0]?.label || getLocalNameInfo(currentClass).localName || currentClass} />
            <Iri />
          </div>
          <ClassBreadcrumbs />
          <Abstract />
          <Description />
          <ClassInfo />
          <div>
            <Links label={data?.[0]?.label || getLocalNameInfo(currentClass).localName || currentClass} />
            <Instances />
          </div>
        </Container>
      )}
    </Paper>
  );
};

export default Class;
