import { CloseBracketConfig } from "@codemirror/autocomplete";
import {
  continuedIndent,
  foldInside,
  foldNodeProp,
  indentNodeProp,
  LanguageSupport,
  LRLanguage,
} from "@codemirror/language";
import { styleTags, tags as t } from "@lezer/highlight";
import {
  indentConstructTemplatePattern,
  indentGroupGraphPattern,
  indentPropertyList,
  noIndent,
  sameColumn,
} from "./indentFunctions.ts";
import { parser } from "./sparqlParser.ts";

export const SparqlLanguage = LRLanguage.define({
  name: "SPARQL 1.1 query",
  parser: parser.configure({
    props: [
      indentNodeProp.add({
        GroupGraphPattern: indentGroupGraphPattern,
        ConstructTemplate: indentConstructTemplatePattern,
        "PropertyListPath PropertyListPathNotEmpty PropertyList PropertyListNotEmpty": indentPropertyList,
        BlankNodePropertyList: sameColumn({ units: 1 }),
        "ObjectList ObjectListPath": sameColumn({ units: 0 }),
        BlankNodePropertyListPath: sameColumn({ units: 1 }),
        Expression: sameColumn({ units: 0 }),
        Bind: sameColumn({ units: 1 }),
        "STRING_LITERAL_LONG1 STRING_LITERAL_LONG2": noIndent,
        SelectClause: continuedIndent(),
        ConstructQuery: indentGroupGraphPattern,
        QuadPattern: indentGroupGraphPattern,
        TriplesTemplate: sameColumn({ units: 0 }),
      }),
      foldNodeProp.add({
        Prologue(tree) {
          // Lets make it fold until the first error child (id: 0 is the error node)
          const hasErrorNodes = tree.getChildren(0)?.[0];
          // Shorten down the prologue to the first keyword. E.g. PREFIX or BASE
          return { from: tree.firstChild!.firstChild!.to, to: hasErrorNodes ? hasErrorNodes.prevSibling!.to : tree.to };
        },
        // Fold nodes delimited by '{' '}' leaving only the braces
        "GroupGraphPattern ConstructTemplate QuadPattern QuadData": foldInside,
        // Fold nodes from the first opening brace to the closing brace (is always the last character)
        "QuadsNotTriples InlineDataOneVar InlineDataFull ConstructQuery"(tree) {
          const bracket = tree.getChild("{");
          const closingBracket = tree.getChildren("}").pop();
          if (bracket) {
            return { from: bracket!.to, to: closingBracket?.from || tree.to - 1 };
          }
          return null;
        },
      }),
      styleTags({
        IRIREF: t.url,
        "Var!": t.special(t.attributeName), // Will style any Var token and ignore any styling of children
        "PNAME_LN PNAME_NS PathMod PathPrimary": t.propertyName,
        Null: t.null,
        "NumericLiteral! INTEGER": t.number,
        "String!": t.string,
        Comment: t.comment,
        Annotation: t.heading,
        "( ) { } [ ]": t.bracket,
        KEYWORD: t.keyword,
        "BooleanLiteral!": t.bool,
      }),
    ],
  }),
  languageData: {
    commentTokens: { line: "#" },
    closeBrackets: <CloseBracketConfig>{ brackets: ["(", "[", "{", "'", '"', '"""'] },
  },
});
export function Sparql() {
  return new LanguageSupport(SparqlLanguage);
}
