import * as Models from "@triply/utils/Models.js";
import { factories, Terms } from "@triplydb/data-factory";

export {
  NtriplyStatement as Statement,
  NtriplyStatements as Statements,
  NtriplyTerm as Term,
} from "@triply/utils/Models.js";

const factory = factories.compliant;

function termToNtriply(term: Terms.Term): Models.NtriplyTerm {
  if (term.termType === "NamedNode") {
    if (term.value.indexOf("/.well-known/genid") > 0) {
      return { termType: "BlankNode", value: term.value };
    } else {
      return { termType: "NamedNode", value: term.value };
    }
  } else if (term.termType === "BlankNode") {
    return { termType: "BlankNode", value: term.value };
  }
  let termInfo: Models.NtriplyTerm = { termType: "Literal", value: term.value };
  const asLiteral = term as Terms.Literal;
  const lang = asLiteral.language;
  if (lang) termInfo.language = lang;
  const datatype = asLiteral.datatype;
  if (datatype && datatype.value) termInfo.datatype = datatype.value;
  return termInfo;
}

export function quadToNtriply(n3Statement: Terms.Quad): Models.NtriplyStatement {
  const statement: Models.NtriplyStatement = [
    termToNtriply(n3Statement.subject),
    termToNtriply(n3Statement.predicate),
    termToNtriply(n3Statement.object),
  ];
  // #2607 Triples are always returned as Quads with a default graph when none is specified
  if (n3Statement.graph.termType !== "DefaultGraph") statement.push(termToNtriply(n3Statement.graph));
  return statement;
}

function NtriplyObjectToTerm(nTriplyObject: Models.NtriplyTerm): Terms.NamedNode | Terms.Literal {
  if (nTriplyObject.termType === "NamedNode") {
    return factory.namedNode(nTriplyObject.value);
  } else {
    return factory.literal(
      nTriplyObject.value,
      nTriplyObject.language || factory.namedNode(nTriplyObject.datatype || ""),
    );
  }
}

export function NTriplyStatementToQuad(nTriplyStatement: Models.NtriplyStatement) {
  const NTriplySubject = nTriplyStatement[0];
  const NTriplyPredicate = nTriplyStatement[1];
  const NTriplyObject = nTriplyStatement[2];
  const NTriplyGraph = nTriplyStatement[3];

  const subject = factory.namedNode(NTriplySubject.value);
  const predicate = factory.namedNode(NTriplyPredicate.value);
  const object = NtriplyObjectToTerm(NTriplyObject);
  const graph = NTriplyGraph ? factory.namedNode(NTriplyGraph.value) : factory.defaultGraph();
  return factory.quad(subject, predicate, object, graph);
}
