import { Alert, FormControlLabel, Radio } from "@mui/material";
import getClassname from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { Token } from "@triply/utils/Models.js";
import { validation } from "@triply/utils-private";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import {
  Button,
  FontAwesomeRoundIcon,
  FormField,
  MaterialRadioButtonGroup,
  MuiTextFieldRedux,
} from "#components/index.ts";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import { Account } from "#reducers/accountCollection.ts";
import { groups } from "./tokenGroups.ts";
import * as styles from "./style.scss";

namespace TokenInfo {
  export interface FormData {
    description: string;
    authLevel: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    currentAccount: Account;
    token?: Token;
    create?: boolean;
    className?: string;
    // onSubmit?:Function,//passed from parent property. Use the `handleSubmit` as actual submit handler for the form
    submit?: React.MouseEventHandler<any>;
    cancel?: React.EventHandler<React.MouseEvent<any>>;
  }
  export interface State {}
}

const descriptionValidator = validation.toStringValidator(validation.tokenDescriptionValidations);

const TokenInfoComponent: React.FC<
  TokenInfo.Props & ReduxForm.InjectedFormProps<TokenInfo.FormData, TokenInfo.Props>
> = ({
  error,
  invalid,
  submitting,
  handleSubmit,
  token,
  pristine,
  submitSucceeded,
  submitFailed,
  cancel,
  className,
}) => {
  const newToken = token && !!token.token;
  const { ref: copyRef, copyToClipboard } = useCopyToClipboard();

  function selectInputValue(event: any, token: string) {
    copyToClipboard(token);
    event.target.select();
  }
  return (
    <div className={className}>
      <form method="POST" onSubmit={handleSubmit}>
        {
          /** Hide this when token string is returned (i.e., new token) **/ !newToken && (
            <>
              <FormField
                label="Token name"
                helperText="Something that allows you to identify this token in the future (e.g., the name of the application that will use it)."
                className="mb-6"
              >
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                  name="description"
                  props={{
                    fullWidth: true,
                    autoFocus: true,
                    formIsPristine: pristine,
                  }}
                  component={MuiTextFieldRedux}
                />
              </FormField>
              <FormField label="Access level" className="mb-6">
                <ReduxForm.Field name="authLevel" component={getRadioButtons} />
              </FormField>
            </>
          )
        }
        {!pristine && error ? (
          <Alert severity="error" variant="outlined">
            {error}
          </Alert>
        ) : null}
        {
          /** Only show this when token string is returned (i.e., new token) **/ newToken && token?.token && (
            <Alert severity="success" className="mb-0" variant="outlined">
              <div ref={copyRef} className="mx-1">
                <div className="mb-3">
                  A new API token was successfully created. Copy and store this token; for security reasons it will not
                  be shown again.
                </div>
                <input
                  className={styles.readonlyToken}
                  readOnly
                  onClick={(e) => selectInputValue(e, token.token!)}
                  type="text"
                  value={token.token}
                />
              </div>
            </Alert>
          )
        }
        <div className="form-group pt-5">
          {!newToken && (
            <LoadingButton
              type="submit"
              color="secondary"
              disabled={invalid || pristine || submitFailed}
              onClick={handleSubmit}
              loading={submitting}
            >
              {token ? "Update" : "Create"}
            </LoadingButton>
          )}
          {!!cancel && (
            <Button onClick={cancel} className="ml-2" variant="text">
              {token ? "Close" : "Cancel"}
            </Button>
          )}
        </div>
      </form>
      {pristine && submitSucceeded && !newToken && (
        <Alert severity="success" className={styles.okAlert} variant="outlined">
          You have successfully updated the API token.
        </Alert>
      )}
    </div>
  );
};

const TokenInfo = ReduxForm.reduxForm<TokenInfo.FormData, TokenInfo.Props>({
  form: "tokenForm",
  validate: memoizee(
    (formData: TokenInfo.FormData) => {
      return {
        description: descriptionValidator(formData.description),
      };
    },
    { max: 10 },
  ),
})(TokenInfoComponent);

// Omitting ref, as it conflicts with redux form
const getRadioButtons = (props: Omit<MaterialRadioButtonGroup.Props, "ref"> & ReduxForm.WrappedFieldProps) => {
  return (
    <div>
      <MaterialRadioButtonGroup {...props} className={getClassname(styles.controls)}>
        {groups.map((group) => {
          const icon = <FontAwesomeRoundIcon className={styles.roundIcon} icon={group.mainField.icon} aria-label="" />;
          const isSelected = group.mainField.name === props.input.value;
          return (
            <FormControlLabel
              key={group.mainField.name}
              className={getClassname(styles.controlLevel, { [styles.active]: isSelected })}
              value={group.mainField.name}
              label={`${group.mainField.label}: ${group.mainField.description}`}
              control={<Radio color="primary" checkedIcon={icon} icon={icon} value={group.mainField.name} />}
            />
          );
        })}
      </MaterialRadioButtonGroup>
    </div>
  );
};

export default TokenInfo;
