import {
  Button as MuiButton,
  Checkbox,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableContainer,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import getClassName from "classnames";
import { goBack, push } from "connected-react-router";
import { capitalize, compact, upperFirst } from "lodash-es";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import { Button, Circle, Dialog, FontAwesomeIcon, Label, MuiTextField } from "#components/index.ts";
import { NEVER } from "#components/ReactTableUtils/HumanizedDateInPastWithoutTitleRenderer.tsx";
import {
  HumanizedDateInFutureRenderer,
  HumanizedDateInPastRenderer,
  HumanizedDateInPastWithoutTitleRenderer,
  NumberRenderer,
  TableFooter,
} from "#components/ReactTableUtils/index.ts";
import ReactTableBody from "#components/ReactTableUtils/TableBody.tsx";
import TableHeader from "#components/ReactTableUtils/TableHeader.tsx";
import AdminInfo from "#containers/Services/ServiceListItem/AdminInfo.tsx";
import Logs from "#containers/Services/ServiceListItem/Logs.tsx";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import {
  deleteServiceFromAdminList,
  issueServiceCommand,
  Service,
  shouldShowServiceAsRunning,
} from "#reducers/services.ts";
import * as tableStyles from "#components/ReactTableUtils/tableStyle.scss";

interface Props {
  services: Service[];
  loading?: boolean;
  error?: string;
  syncService: (service: Service) => void;
  removeService: (service: Service) => void;
  updateServiceBulk: (services: Service[]) => void;
  recreateServiceBulk: (services: Service[]) => void;
  removeServiceBulk: (services: Service[]) => void;
}

export function isExpired(service: Service) {
  return service.error && service.error.message === "This service has expired.";
}
export function hasFatalError(service: Service) {
  if (isExpired(service)) return false;
  return (
    (service.foundInDocker === false && service.status !== "stopped") || // it's fine if a container does not exist, as long as the service is stopped
    service.foundInMongo === false ||
    service.status === "error"
  );
}

const ShowLogsButton: React.FC<{ service: Service }> = ({ service }) => {
  const dispatch = useDispatch();
  const goBackCb = React.useCallback(() => dispatch(goBack()), [dispatch]);
  const location = useLocation<{ logsModalShownFor?: string }>();

  return (
    <>
      <Dialog
        open={!!location.state?.logsModalShownFor && location.state.logsModalShownFor === service.id}
        onClose={goBackCb}
        fullScreen
        closeButton
      >
        <Logs
          apiServicePath={`/datasets/${service.dataset?.owner.accountName}/${service.dataset?.name}/services/${service.name}`}
        />
      </Dialog>
      <IconButton
        title="Show logs"
        aria-label="Show logs"
        disabled={!service.foundInDocker}
        onClick={() =>
          dispatch(
            push({
              state: { logsModalShownFor: service.id },
            }),
          )
        }
      >
        <FontAwesomeIcon icon="stream" />
      </IconButton>
    </>
  );
};
const AdminInfoButton: React.FC<{ service: Service; hasGraphError: boolean }> = ({ service, hasGraphError }) => {
  const dispatch = useDispatch();
  const goBackCb = React.useCallback(() => dispatch(goBack()), [dispatch]);
  const location = useLocation<{ adminInfoModalShownFor?: string }>();
  let renderedStatusLevel: "error" | "warning" | "info" = "info";
  if (hasFatalError(service)) {
    renderedStatusLevel = "error";
  } else if (hasGraphError) {
    renderedStatusLevel = "warning";
  }

  return (
    <>
      <Dialog
        open={!!location.state?.adminInfoModalShownFor && location.state.adminInfoModalShownFor === service.id}
        onClose={goBackCb}
        maxWidth="xl"
        fullWidth
        closeButton
        spacing
      >
        <AdminInfo service={service} />
      </Dialog>
      <IconButton
        color={renderedStatusLevel}
        title="Open additional info"
        aria-label="Open additional info"
        onClick={() =>
          dispatch(
            push({
              state: { adminInfoModalShownFor: service.id, preserveScrollPosition: true },
            }),
          )
        }
      >
        <FontAwesomeIcon icon={renderedStatusLevel !== "info" ? ["fas", "exclamation-triangle"] : "info-circle"} />
      </IconButton>
    </>
  );
};

function getCircleColor(service: Service) {
  if (!service) return undefined;
  if (service.status === "updating" || service.status === "starting") return "flash-green";
  if (shouldShowServiceAsRunning(service)) return "green";
  if (service.status === "stopping") return "flash-orange";
  if (service.status === "error" && !isExpired(service)) return "red";
  return undefined;
}

/**
 * Custom renderers
 */
const UserLinkRenderer: React.FC<CellContext<Service, string>> = ({ cell }) => {
  const value = cell.getValue();
  if (!value) return <i>Unknown</i>;
  return <Link to={`/${value}`}>{value}</Link>;
};
const DatasetLinkRenderer: React.FC<CellContext<Service, string>> = ({ cell, row: { original } }) => {
  const value = cell.getValue();
  if (!original.dataset) return <i>Unknown</i>;
  return <Link to={`/${original.dataset.owner.accountName}/${value}`}>{value}</Link>;
};
const ServiceLinkRenderer: React.FC<CellContext<Service, string>> = ({ cell, row: { original } }) => {
  const value = cell.getValue();
  if (original.foundInMongo === false) {
    // Cannot draw link anyway.Instead, add an alert
    return <Label error>Missing mongo metadata</Label>;
  }
  if (!original.dataset) return <i>Unknown</i>;
  const link = (
    <Link to={`/${original.dataset.owner.accountName}/${original.dataset?.name}/services#${original.name}`}>
      {value}
    </Link>
  );
  if (original.foundInDocker === false && original.status !== "error" && original.status !== "stopped") {
    //If the metadata has an error or when it's stopped, then the docker container may not exist
    //I.e., better to not show this message
    return (
      <>
        <div>
          <Label error>Docker container not found</Label>
        </div>
        {link}
      </>
    );
  }
  return link;
};
const StatusRenderer: React.FC<CellContext<Service, Models.ServiceStatus>> = ({ cell, row }) => {
  let statusText: string = cell.getValue();
  if ((statusText === "stopped" || statusText === "stopping") && row.original.autoResume) {
    statusText += " (with auto-resume)";
  } else if (isExpired(row.original)) {
    statusText = "Expired";
  }

  return (
    <div className="flex" title={row.original.error?.message}>
      <Circle color={getCircleColor(row.original)} />
      <div className={"pl-2"} style={{ wordBreak: "keep-all" }}>
        {capitalize(statusText)}
      </div>
    </div>
  );
};
const ServiceTypeRenderer: React.FC<CellContext<Service, Models.ServiceType>> = ({ cell }) => {
  const value = cell.getValue();
  if (!value) return <i>Unknown</i>;
  return <span>{capitalize(value)}</span>;
};

const GraphsRenderer: React.FC<CellContext<Service, unknown>> = ({ row: { original } }) => {
  const history = useHistory();
  const numberOfGraphErrors = original.numberOfGraphErrors || 0;
  return (
    <div className="flex center">
      {original.numberOfLoadedGraphs ?? "0"} /{" "}
      {original.numberOfGraphs || (original.dataset && original.dataset.graphCount) || "unknown"}
      {numberOfGraphErrors > 0 && (
        <IconButton
          size="small"
          color="secondary"
          title={`${numberOfGraphErrors} graph${numberOfGraphErrors > 1 ? "s have" : " has"} an error`}
          aria-label={`${numberOfGraphErrors} graph${numberOfGraphErrors > 1 ? "s have" : " has"} an error`}
          className="ml-2 mb-1"
          onClick={() =>
            history.push(`/${original.dataset?.owner.accountName}/${original.dataset?.name}/services#${original.name}`)
          }
        >
          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
        </IconButton>
      )}
    </div>
  );
};
const TableServiceVersion: React.FC<CellContext<Service, string>> = ({ cell }) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = React.useState(false);
  const text = "A new version of the service is available. Click to update the service to the latest version.";
  const value = cell.getValue();
  const executeClick = () => {
    setClicked(true);
    dispatch<typeof issueServiceCommand>(
      issueServiceCommand(cell.row.original.dataset!.owner, cell.row.original.dataset!, cell.row.original, "restart"),
    ).catch(() => {}); // Redux will handle the error
  };
  if (value === undefined) {
    return <></>;
  } else {
    return (
      <>
        <span>{value}</span>
        {cell.row.original?.canUpdate && (
          <IconButton
            size="small"
            color="warning"
            title={text}
            aria-label={text}
            onClick={executeClick}
            disabled={clicked}
          >
            <FontAwesomeIcon icon="arrow-up" />
          </IconButton>
        )}
      </>
    );
  }
};

const TableServiceActions: React.FC<CellContext<Service, any>> = ({ row }) => {
  const acl = useAcl();
  const hasGraphError = !!row.original.numberOfGraphErrors;
  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const deleteServiceHandler = (service: Service) => {
    confirm({
      title: "Delete service",
      description: `Are you sure you want to delete this service?`,
      actionLabel: "Delete",
      onConfirm: () => {
        dispatch<typeof deleteServiceFromAdminList>(deleteServiceFromAdminList(service)).catch(() => {});
      },
    });
  };
  const syncServiceHandler = (service: Service) => {
    if (service.dataset) {
      confirm({
        title: "Sync service",
        description: `Are you sure you want to update this service?`,
        actionLabel: "Synchronize",
        onConfirm: () => {
          if (service?.dataset) {
            dispatch<typeof issueServiceCommand>(
              issueServiceCommand(service.dataset.owner, service.dataset, service, "sync"),
            )
              .then(() => {
                if (service.dataset)
                  dispatch(
                    push({ pathname: `/${service.dataset.owner.accountName}/${service.dataset.name}/services` }),
                  );
              })
              .catch(() => {});
          }
        },
      });
    }
  };

  return (
    <div className={tableStyles.actionButtons}>
      {acl.check({ action: "showServiceDebugInformation" }).granted && (
        <>
          <AdminInfoButton service={row.original} hasGraphError={hasGraphError} />
          <ShowLogsButton service={row.original} />
        </>
      )}
      <IconButton
        color="secondary"
        disabled={
          !row.original.outOfSync ||
          !shouldShowServiceAsRunning(row.original) ||
          !!row.original.error || // this also caters for expired services
          hasFatalError(row.original)
        }
        title={
          row.original.outOfSync
            ? "Click to sync the service"
            : "This service is in sync with the current state of the dataset"
        }
        aria-label={
          row.original.outOfSync
            ? "Click to sync the service"
            : "This service is in sync with the current state of the dataset"
        }
        onClick={() => syncServiceHandler(row.original)}
      >
        <FontAwesomeIcon icon="sync" />
      </IconButton>
      <IconButton color="error" onClick={() => deleteServiceHandler(row.original)} aria-label="Remove service">
        <FontAwesomeIcon icon="times" />
      </IconButton>
    </div>
  );
};

/**
 * Column config
 */

function getColumns(mayAutostop: boolean): ColumnDef<Service, any>[] {
  return compact([
    {
      id: "selection",
      size: 20,
      header: ({ table }) => {
        return (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={(e) => table.toggleAllRowsSelected(e.target.checked)}
            color="primary"
          />
        );
      },
      cell: ({ cell }) => {
        return (
          <Checkbox checked={cell.row.getIsSelected()} onChange={(e) => cell.row.toggleSelected(e.target.checked)} />
        );
      },
      enableColumnFilter: false,
    },
    {
      header: "Name",
      accessorFn: (service) => service.name || "",
      filterFn: "includesString",
      sortingFn: "alphanumeric",
      cell: ServiceLinkRenderer,
    },
    {
      header: "Type",
      accessorKey: "type",
      filterFn: "equals",
      cell: ServiceTypeRenderer,
    },
    {
      header: "Status",
      accessorKey: "status",
      filterFn: "equals",
      cell: StatusRenderer,
    },
    {
      header: "Loaded statements",
      accessorFn: (service) => service.numberOfLoadedStatements ?? 0,
      filterFn: "inNumberRange",
      cell: NumberRenderer,
      enableGlobalFilter: false,
    },
    {
      header: "Loaded graphs",
      accessorKey: "numberOfLoadedGraphs",
      cell: GraphsRenderer,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      header: "Owner",
      accessorFn: (service) => service.dataset?.owner.accountName || "",
      cell: UserLinkRenderer,
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Dataset",
      accessorFn: (service) => service.dataset?.name || "",
      cell: DatasetLinkRenderer,
      sortingFn: "alphanumeric",
      filterFn: "includesString",
    },
    {
      header: "Statements in dataset",
      accessorFn: (service) => {
        return service.dataset?.statements ?? 0;
      },
      filterFn: "inNumberRange",
      cell: NumberRenderer,
      enableGlobalFilter: false,
    },
    {
      header: "Created",
      accessorKey: "createdAt",
      cell: HumanizedDateInPastRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Last import",
      accessorKey: "loadedAt",
      cell: HumanizedDateInPastRenderer,
      enableColumnFilter: false,
    },
    {
      header: "Last queried",
      sortingFn: (rowA, rowB): number => {
        const a = rowA.original.queriedAt === NEVER ? 10000 : parseInt(rowA.original.queriedAt ?? "0");
        const b = rowB.original.queriedAt === NEVER ? 10000 : parseInt(rowB.original.queriedAt ?? "0");
        if (typeof a === "number" && typeof b === "number") return a - b;
        return 0;
      },
      cell: HumanizedDateInPastWithoutTitleRenderer,
      accessorFn: (row) => row.queriedAt ?? NEVER,
      enableColumnFilter: false,
    },
    mayAutostop
      ? {
          header: "Auto stops",
          accessorKey: "autostopsAt",
          cell: HumanizedDateInFutureRenderer,
          enableColumnFilter: false,
        }
      : undefined,
    {
      header: "Version",
      accessorFn: (service) => service.version,
      cell: TableServiceVersion,
      filterFn: "equals",
    },
    {
      id: "actions",
      header: "Actions",
      cell: TableServiceActions,
    },
  ]);
}
const defaultColumn: Partial<ColumnDef<Service, any>> = {
  cell: (props) => {
    const val = props.getValue();
    if (val === undefined) return null;
    return <span>{val}</span>;
  },
};

const ServicesTable: React.FC<Props> = ({
  services,
  loading,
  error,
  updateServiceBulk,
  removeServiceBulk,
  recreateServiceBulk,
}) => {
  const acl = useAcl();
  const columns = React.useMemo(() => getColumns(acl.check({ action: "stopServiceWithAutostart" }).granted), [acl]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const debouncedSetGlobalFilter = useDebounce(setGlobalFilter, 200);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [openToggleColumnsMenu, setOpenToggleColumnsMenu] = React.useState(false);
  const menuAnchorRef = React.useRef<any>();
  const ignoreColumnVisibilty = ["Name", "actions", "selection"];

  const table = useReactTable<Service>({
    columns: columns,
    data: services,
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      columnVisibility: columnVisibility,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    defaultColumn: defaultColumn,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (service) => service.id,
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <Paper square className={tableStyles.tablePaper}>
      <Toolbar className={getClassName("mb-3 g-7 flex", tableStyles.alignEnd)}>
        <Typography variant="h4">Services</Typography>
        {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && ( // This library should take some grammar lessons
          <>
            <Button
              color="secondary"
              disabled={!table.getSelectedRowModel().flatRows.some((row) => row.original.canUpdate)}
              onClick={() => updateServiceBulk(table.getSelectedRowModel().flatRows.map((row) => row.original))}
            >
              Update selected service(s)
            </Button>
            <Button
              color="warning"
              disabled={!table.getSelectedRowModel().flatRows.some((row) => row.original.dataset?.owner !== undefined)}
              onClick={() => recreateServiceBulk(table.getSelectedRowModel().flatRows.map((row) => row.original))}
            >
              Recreate selected service(s)
            </Button>
            <Button
              color="error"
              onClick={() => removeServiceBulk(table.getSelectedRowModel().flatRows.map((row) => row.original))}
            >
              Remove selected services(s)
            </Button>
          </>
        )}
        <MuiButton
          variant="outlined"
          aria-label={openToggleColumnsMenu ? "Hide columns menu" : "Show columns menu"}
          onClick={() => {
            setOpenToggleColumnsMenu(!openToggleColumnsMenu);
          }}
          ref={menuAnchorRef}
          endIcon={<FontAwesomeIcon icon={openToggleColumnsMenu ? "chevron-up" : "chevron-down"} />}
        >
          Columns
        </MuiButton>
        <Menu
          open={openToggleColumnsMenu}
          anchorEl={menuAnchorRef.current}
          onClose={() => {
            setOpenToggleColumnsMenu(false);
          }}
        >
          {table.getAllLeafColumns().map((column) => {
            if (!ignoreColumnVisibilty.includes(column.id))
              return (
                <MenuItem key={column.id} value={column.id} onClick={column.getToggleVisibilityHandler()}>
                  <Checkbox checked={column.getIsVisible()} onChange={column.getToggleVisibilityHandler()} />
                  {upperFirst(column.id)}
                </MenuItem>
              );
          })}
        </Menu>
        <div className={tableStyles.space} />
        <MuiTextField
          label="Search"
          onChange={(e) => debouncedSetGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon="search" />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <TableContainer className={tableStyles.tableContainer}>
        <div className="px-5">
          <Table size="small">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <ReactTableBody
              rows={table.getRowModel().rows}
              loading={loading}
              error={error}
              columnCount={columns.length}
            />
            <TableFooter
              currentPage={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              rowCount={table.getPrePaginationRowModel().rows.length}
              onChangePage={table.setPageIndex}
              onChangeRowsPerPage={table.setPageSize}
            />
          </Table>
        </div>
      </TableContainer>
    </Paper>
  );
};

export default ServicesTable;
