import * as React from "react";
import { NtriplyStatements } from "@triply/utils/Models.js";
import { NTriplyStatementToQuad } from "@triply/utils-private/nTriply.js";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { Button, ConfirmationDialog, FontAwesomeIcon } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";

const factory = factories.compliant;
const RemoveStatements: React.FC<{
  quadsToRemove: NtriplyStatements;
}> = ({ quadsToRemove }) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;

  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const _quadsToRemove = quadsToRemove.map((tquad) => NTriplyStatementToQuad(tquad));
  const valuesToRemove = `
          ${_quadsToRemove
            .map((quad) => {
              let statement = "";

              if (quad.graph) {
                statement += `graph ${termToString(quad.graph)} {
                      ${termToString(quad.subject)} ${termToString(quad.predicate)} ${termToString(quad.object)}
                  }`;
              } else {
                statement += `${termToString(quad.subject)} ${termToString(quad.predicate)} ${termToString(quad.object)}`;
              }

              return statement;
            })
            .join("\n")}
      `;

  return (
    <>
      <Button
        color="error"
        elevation
        onClick={() => setOpen(true)}
        title={`Delete ${quadsToRemove.length} statement(s)`}
        startIcon={<FontAwesomeIcon icon="trash" />}
        size="small"
        className="m-2 mt-5 "
      >
        {`Delete ${quadsToRemove.length} statement(s)`}
      </Button>
      <ConfirmationDialog
        open={open}
        loading={saving}
        onClose={() => setOpen(false)}
        actionLabel={`Delete ${quadsToRemove.length} statement(s)`}
        title={`Delete ${quadsToRemove.length} statement(s)?`}
        onConfirm={async () => {
          setSaving(true);

          const query = `
                        delete {
                        ${valuesToRemove}
                        } where {}`;

          const body = new FormData();
          body.set("update", query);

          await fetch(updateUrl, {
            credentials: "same-origin",
            method: "POST",
            body: body,
          });

          await dispatch<typeof refreshDatasetsInfo>(
            refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
          );
          await dispatch<typeof getGraphs>(
            getGraphs({
              accountName: currentDs.owner.accountName,
              datasetName: currentDs.name,
              datasetId: currentDs.id,
            }),
          );

          setSaving(false);
          setOpen(false);
        }}
        description={`Are you sure you want to delete ${quadsToRemove.length} statement(s) from the dataset?`}
      />
    </>
  );
};

export default RemoveStatements;
