import fs from "fs";
import { DeepOmit } from "ts-essentials";
import { TriplyDBChartSchema } from "@triply/utils-private/values.js";
/**
 * Exclude props that we don't need or use
 */
export type Values = DeepOmit<
  TriplyDBChartSchema,
  {
    // May also be passed via a secret
    license: never;
    redis: never;
    mongodb: never;
    "mongodb-operator": never;
    mongodbSubChart: never;
    extraDeploy: never;
    cronjobs: never;
  }
>;
const valuesFile = process.env.VALUES_FILE || "/home/triply/values/values.json";
let values: Values | undefined;
let latestValuesFileMTime = 0;
export function getValues() {
  if (!values) {
    values = JSON.parse(fs.readFileSync(valuesFile, "utf-8"));

    // only watch in production. Easy way to get this to work nicefully with unit tests,
    // that otherwise hang because of the open watch
    if (process.env.NODE_ENV === "production") {
      fs.watch(valuesFile, () => {
        /**
         * We noticed that on certain environments (not locally!) the watch event triggers
         * every ~30 seconds.
         * This seems to be caused by the use of different volume.
         * To avoid superfluous log statements, we compare the modified (not the changed) date, and accept that
         * we read this file every ~30 seconds.
         */
        const lastModified = fs.statSync(valuesFile).mtimeMs;
        if (lastModified > latestValuesFileMTime) {
          latestValuesFileMTime = lastModified;
          console.info("Reloading values file");
          values = JSON.parse(fs.readFileSync(valuesFile, "utf-8"));
        }
      });
    }
  }

  return values as Values;
}
