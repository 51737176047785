import { Alert } from "@mui/material";
import { filter, forEach } from "lodash-es";
import * as React from "react";
import { NtriplyStatements } from "@triply/utils/Models.js";
import { NTriplyStatementToQuad, quadToNtriply } from "@triply/utils-private/nTriply.js";
import { factories, getWriter, parse } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { Button, ConfirmationDialog, Dialog, FontAwesomeIcon, LoadingButton } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import QuadEditor from "../QuadEditor/index";
import * as styles from "../style.scss";

const factory = factories.compliant;

const AddStatements: React.FC<{}> = ({}) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const [quadsToAdd, setQuadsToAdd] = React.useState<NtriplyStatements>([]);
  const [error, setError] = React.useState<string | undefined>();
  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const dispatch = useDispatch();
  const prefixes = useDatasetPrefixes();
  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      setOpen(false);
    }
  };
  const _prefixes = filter(
    prefixes,
    (prefix) => prefix.prefixLabel === "graph" || prefix.prefixLabel === "rdf" || prefix.prefixLabel === "rdfs",
  );
  const startingTemplate = `${_prefixes.map((prefix) => `@prefix ${prefix.prefixLabel}: ${termToString(factory.namedNode(prefix.iri))}.`).join("\n")}

graph:default {
  <subject> <predicate> <object>
}
  `;

  const [serializedString, setSerializedString] = React.useState<string | undefined>(startingTemplate);

  async function submitChanges() {
    setSaving(true);

    const _quadsToAdd = quadsToAdd.map((tquad) => NTriplyStatementToQuad(tquad));
    const valuesToAdd = `
        ${_quadsToAdd
          .map((quad) => {
            let statement = "";

            if (quad.graph) {
              statement += `graph ${termToString(quad.graph)} {
                        ${termToString(quad.subject)} ${termToString(quad.predicate)} ${termToString(quad.object)}
                    }`;
            } else {
              statement += `${termToString(quad.subject)} ${termToString(quad.predicate)} ${termToString(quad.object)}`;
            }

            return statement;
          })
          .join("\n")}
    `;

    const query = `
    insert {
    ${valuesToAdd}
    } where {}
    `;

    const body = new FormData();
    body.set("update", query);

    await fetch(updateUrl, {
      credentials: "same-origin",
      method: "POST",
      body: body,
    });

    await dispatch<typeof refreshDatasetsInfo>(
      refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
    );
    await dispatch<typeof getGraphs>(
      getGraphs({
        accountName: currentDs.owner.accountName,
        datasetName: currentDs.name,
        datasetId: currentDs.id,
      }),
    );

    setSaving(false);
    setOpen(false);
  }

  return (
    <>
      <Button
        color="primary"
        elevation
        onClick={() => setOpen(true)}
        title={`Add statements`}
        startIcon={<FontAwesomeIcon icon="plus" />}
        size="small"
        className="m-2 mt-5 "
      >
        {`Add statements`}
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now, all changes will be lost."
      />

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        title={`Add statements`}
        closeButton
        disableEscapeKeyDown
      >
        <div className="px-5 pb-5">
          <div className={styles.quadEditorContainer}>
            <QuadEditor
              className={styles.quadEditor}
              serializedString={serializedString}
              prefixes={prefixes}
              onChange={(values) => {
                if (values.updatedString !== serializedString) {
                  setDirty(true);
                } else {
                  setDirty(false);
                }
                if (values.updatedString) {
                  try {
                    const n3Statements = parse(values.updatedString, {
                      baseIri: `${consoleUrl}/${currentDs.owner.accountName}/${currentDs.name}`,
                    });
                    const updatedQuads = n3Statements.map((n3) => quadToNtriply(n3));
                    setQuadsToAdd(updatedQuads);
                    setError(undefined);
                  } catch (e: any) {
                    setError(e.message);
                  }
                }
              }}
            />
          </div>
          {error && <Alert severity="error">{error}</Alert>}
          <div className="flex">
            <LoadingButton
              onClick={submitChanges}
              className="mt-2"
              type="submit"
              color="secondary"
              elevation
              disabled={!isDirty || error !== undefined || quadsToAdd.length < 1}
              loading={saving}
              aria-label="Submit"
            >
              {!isDirty || quadsToAdd.length < 1
                ? "No changes"
                : error
                  ? "Invalid"
                  : `Add ${quadsToAdd.length} statement(s)`}
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddStatements;
