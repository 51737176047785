import { diff, IChange } from "json-diff-ts";
import { FormValues } from "./Types";

type Actions = "UPDATE" | "REMOVE" | "ADD";
type ChangeObject = {
  type: Actions;
  property: string;
  value: { oldValue: string; newValue: string } | string;
};
type Properties = {
  [property: string]: PropBody[];
};
interface PropBody {
  properties?: Properties;
  value?: number | string | boolean;
}

// These vales are removed every time a field is updated, or are properties that are filled in by the components at a later stage. (Basically everything that is not an IRI)
const PROPERTIES_TO_SKIP: string[] = [
  "predicate",
  "rawValue",
  "language",
  "key",
  "datatype",
  "iri",
  "label",
  "description",
  "nestedNode",
];

function parseNestedProperties(properties: Properties, depth: number) {
  let rapport = "";
  for (const [property, values] of Object.entries(properties)) {
    rapport += `${"\t".repeat(depth)}Property ${property.replace(/ /g, ".")} with values\n`;
    for (const value of values) {
      rapport += `${"\t".repeat(depth + 1)}${value.value}\n`;
    }
  }
  return rapport;
}
function redotLink(link: string) {
  return link.replace(/ /g, ".");
}

function parseNewChanges(changes: IChange[], depth = 0, lastSubject?: string) {
  let rapport = "";
  for (const change of changes) {
    if (change.key === "type") {
      if (change.type === "UPDATE") {
        rapport += `${"\t".repeat(depth + 1)}Updated type from ${change.changes?.[0]?.oldValue} to ${change.changes?.[0].value}\n`;
      }
      if (change.type === "ADD") {
        rapport += `${"\t".repeat(depth + 1)}With type ${change?.value.id}\n`;
      }
    } else if (change.changes && change.embeddedKey === "value") {
      // Direct properties
      rapport += `${"\t".repeat(depth)}For property ${redotLink(change.key)}\n`;
      rapport += parseNewChanges(change.changes, depth + 1, lastSubject);
    } else if (change.changes && change.key === "properties" && change.type === "UPDATE") {
      // Updates of nested properties (prabably the properties key of a nested node)
      rapport += parseNewChanges(change.changes, depth + 1, lastSubject);
    } else if (change.key === "properties" && change.type === "ADD") {
      // When adding something we should emit the properties and (nested) values as well
      for (const [property, values] of Object.entries(change.value as Properties)) {
        rapport += `${"\t".repeat(depth + 1)}Property ${redotLink(property)} with values\n`;
        for (const value of values) {
          // Nested node property
          if ("nodeKind" in value && value.nodeKind === "NestedNode" && !!value.properties) {
            rapport += `${"\t".repeat(depth + 2)}Created ${value.value} with\n`;
            rapport += parseNestedProperties(value.properties, depth + 3);
          } else {
            // Normal property
            rapport += `${"\t".repeat(depth + 2)}${value.value}\n`;
          }
        }
      }
    } else if (!change.changes) {
      // Leaf values
      rapport += "\t".repeat(depth);
      switch (change.type) {
        case "ADD":
          // Add new instance
          if ("nodeKind" in change.value && change.value.nodeKind === "NestedNode") {
            rapport += `Added ${change.value.value} with\n`;
            parseNestedProperties(change.value.properties, depth + 1);
            // Add new property to exising
          } else if (Array.isArray(change.value)) {
            if (change.value.length === 0) {
              // Optional properties with no values will give an empty list
              continue;
            }
            rapport += `Added property ${redotLink(change.key)} with values\n`;
            for (const value of change.value) {
              rapport += `${"\t".repeat(depth + 1)}${value.value}\n`;
            }
          } else {
            rapport += `Added ${change.value.value}\n`;
          }
          break;
        case "REMOVE":
          if (Array.isArray(change.value)) {
            // Removed properties
            rapport += `Removed property ${redotLink(change.key)} with values\n`;
            for (const removal of change.value) {
              rapport += `${"\t".repeat(depth + 1)}${removal.value}\n`;
            }
          } else if (change.value) {
            // Removed values
            rapport += `Removed ${change.value.value}\n`;
          }
          break;
        case "UPDATE":
          rapport += `Updated ${change.value.oldValue} to ${change.value.newValue}\n`;
      }
    } else if (change.changes) {
      rapport += parseNewChanges(change.changes, depth);
    }
  }
  return rapport;
}

export function getChangeDiff(values: FormValues, initialValues?: FormValues, isCopy?: boolean): string {
  const embeddedObjKeys = {
    // Tells the difftool to use the values as keys in arrays, this makes sure that we don't get array placement updates, but also loses us the information if an array item has been edited.
    // Can't use '*' in the selector position unfortunately, so we need to parse the object and find all the property keys
    ...Object.keys(values.properties).reduce<{ [key: string]: string }>((prev, propertyKey) => {
      if (values.properties[propertyKey].length !== 0) {
        if (
          values.properties[propertyKey][0]?.nodeKind === "IRI" ||
          values.properties[propertyKey][0]?.nodeKind === "Literal"
        ) {
          prev[`.properties.${propertyKey}`] = "value";
        } else if (values.properties[propertyKey][0]?.nodeKind === "NestedNode") {
          prev[`.properties.${propertyKey}`] = "value";
          values.properties[propertyKey].forEach((option) => {
            option?.nodeKind === "NestedNode" &&
              Object.keys(option.properties).forEach((subKey) => {
                prev[`.properties.${propertyKey}.properties.${subKey}`] = "value";
              });
          });
        }
      }
      return prev;
    }, {}),
  };
  const initialDiff = diff(initialValues || {}, values, {
    keysToSkip: PROPERTIES_TO_SKIP,
    embeddedObjKeys: embeddedObjKeys,
  });
  const rapportHeading = `${initialValues === undefined ? "Created instance" : isCopy ? "Copied instance" : "Updated instance"} ${((values as any).value || values.iri).replace(/ /g, ".")}\n`;
  const newChanges = parseNewChanges(initialDiff);
  return rapportHeading + newChanges;
}
