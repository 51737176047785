import * as React from "react";
import { useCachedSparql } from "../useCachedSparql";
import { allHierarchyPropertiesQuery, allSchemesQuery, conceptSchemeHierarchy } from "./queries";
import { Scheme } from "./SchemeSelector";

interface Hierarchy {
  parentConceptScheme: string;
  childConceptScheme: string;
}

export interface HierarchyProperty {
  property: string;
  direction: "broader" | "narrower";
}

interface SkosTreeContext {
  schemes: Scheme[];
  hierarchy: Hierarchy[];
  hierarchyProperties?: HierarchyProperty[];
  loading: boolean;
}

export const skosTreeContext = React.createContext<SkosTreeContext>({
  schemes: [],
  hierarchy: [],
  hierarchyProperties: undefined,
  loading: false,
});

const SkosTreeContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: schemes = [], loading: schemesLoading } = useCachedSparql<Scheme[]>(allSchemesQuery());
  const { data: hierarchy = [], loading: hierarchyLoading } = useCachedSparql<Hierarchy[]>(conceptSchemeHierarchy());
  const { data: hierarchyProperties, loading: hierarchyPropertiesLoading } =
    useCachedSparql<HierarchyProperty[]>(allHierarchyPropertiesQuery());

  return (
    <skosTreeContext.Provider
      value={{
        schemes,
        hierarchy,
        hierarchyProperties,
        loading: schemesLoading || hierarchyLoading || hierarchyPropertiesLoading,
      }}
    >
      {children}
    </skosTreeContext.Provider>
  );
};

export default SkosTreeContextProvider;
