import * as React from "react";
import { SelectResult } from "#components/Sparql/SparqlUtils.ts";
import { useCurrentDataset } from "../../reducers/datasetManagement";
import fetch from "../fetch";
import useConstructUrlToApi from "./useConstructUrlToApi";

const useSparqlRaw = <R>(query: string | false) => {
  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });
  const [data, setData] = React.useState<SelectResult>();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [resultsFor, setResultsFor] = React.useState<string>();

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/sparql-results+json",
        },
        body: JSON.stringify({
          account: currentDs.owner.accountName,
          dataset: currentDs.name,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    [sparqlUrl, currentDs.owner.accountName, currentDs.name],
  );

  React.useEffect(() => {
    if (!query) return;
    setLoading(true);
    setError(undefined);
    const abortController = new AbortController();
    let aborted = false;
    sparql(query, abortController.signal)
      .then((result) => {
        if (!aborted) {
          setData(result);
          setResultsFor(query);
        }
      })
      .catch((e) => {
        if (!aborted) setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      aborted = true;
      abortController.abort(new Error("Not needed anymore."));
    };
  }, [query, sparql, currentDs.lastGraphsUpdateTime]);

  return { data, error, loading, resultsFor };
};

export default useSparqlRaw;
