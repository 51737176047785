import { IconButton, InputAdornment, Paper, Table, TableContainer, Toolbar, Typography } from "@mui/material";
import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import getClassName from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import { FontAwesomeIcon, MuiTextField } from "#components/index.ts";
import { HumanizedDateInPastRenderer, NumberRenderer, TableFooter } from "#components/ReactTableUtils/index.ts";
import ColumnVisibility from "#components/ReactTableUtils/PersistedColumnVisibilityButton.tsx";
import ReactTableBody from "#components/ReactTableUtils/TableBody.tsx";
import TableHeader from "#components/ReactTableUtils/TableHeader.tsx";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import { Dataset } from "#reducers/datasetCollection.ts";
import { deleteDataset } from "#reducers/datasetManagement.ts";
import * as tableStyles from "#components/ReactTableUtils/tableStyle.scss";

interface Props {
  datasets: Dataset[];
  loading?: boolean;
  error?: string;
}

/**
 * Render components
 */

const UserLinkRenderer: React.FC<CellContext<Dataset, string>> = ({ cell }) => {
  const value = cell.getValue();
  return <Link to={`/${value}`}>{value}</Link>;
};
const DatasetLinkRenderer: React.FC<CellContext<Dataset, string>> = ({ row, cell }) => {
  const value = cell.getValue();
  return <Link to={`/${row.original.owner.accountName}/${value}`}>{value}</Link>;
};

const AccessLevelRenderer: React.FC<CellContext<Dataset, Models.AccessLevel>> = (cell) => {
  return <AccessLevelIcon level={cell.getValue()} type="dataset" />;
};
const DataQualityIssuesRenderer: React.FC<CellContext<Dataset, "No issues" | "Data quality issues">> = (cell) => {
  if (cell.getValue() === "Data quality issues") {
    return (
      <div>
        <FontAwesomeIcon size="xl" className="warning" fixedWidth icon={["fas", "exclamation-triangle"]} />
        Data quality issues
      </div>
    );
  }
  return null;
};

const DatasetTableActions: React.FC<CellContext<Dataset, unknown>> = ({ row }) => {
  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const deleteDatasetHandler = (dataset: Dataset) => {
    confirm({
      title: "Delete dataset",
      description: `Are you sure you want to delete dataset '${dataset.name}'?`,
      actionLabel: "Delete",
      onConfirm: () => {
        dispatch<typeof deleteDataset>(deleteDataset(dataset)).catch(() => {});
      },
    });
  };
  return (
    <div className={tableStyles.actionButtons}>
      <Link to={`/${row.original.owner.accountName}/${row.original.name}/settings`}>
        <IconButton size="small" title="Go to dataset settings" aria-label="Go to dataset settings">
          <FontAwesomeIcon size="sm" icon={["fas", "cog"]} />
        </IconButton>
      </Link>
      <IconButton color="error" onClick={() => deleteDatasetHandler(row.original)} aria-label="Remove service">
        <FontAwesomeIcon icon="times" />
      </IconButton>
    </div>
  );
};
/**
 * Column Config
 */
const columns: Array<ColumnDef<Dataset, any>> = [
  {
    header: "Name",
    accessorKey: "name",
    cell: DatasetLinkRenderer,
    sortingFn: "alphanumeric",
    filterFn: "includesString",
  },
  {
    header: "Access level",
    accessorKey: "accessLevel",
    cell: AccessLevelRenderer,
    filterFn: "equals",
  },
  {
    header: "DisplayName",
    accessorKey: "displayName",
    sortingFn: "alphanumeric",
  },
  {
    header: "Owner",
    accessorFn: (doc) => doc.owner.accountName,
    cell: UserLinkRenderer,
    sortingFn: "alphanumeric",
  },
  {
    header: "Graph count",
    accessorKey: "graphCount",
    cell: NumberRenderer,
    filterFn: "inNumberRange",
    enableGlobalFilter: false,
  },
  {
    header: "Data quality issues",
    accessorFn: (doc) => (doc.hasDataQualityIssues ? "Data quality issues" : "No issues"),
    cell: DataQualityIssuesRenderer,
    filterFn: "equals",
  },
  {
    header: "Statement count",
    accessorKey: "statements",
    cell: NumberRenderer,
    filterFn: "inNumberRange",
    enableGlobalFilter: false,
  },

  {
    header: "Service count",
    accessorKey: "serviceCount",
    cell: NumberRenderer,
    filterFn: "inNumberRange",
    enableGlobalFilter: false,
  },
  {
    header: "Asset count",
    accessorKey: "assetCount",
    cell: NumberRenderer,
    filterFn: "inNumberRange",
    enableGlobalFilter: false,
  },
  {
    header: "Created at",
    accessorKey: "createdAt",
    cell: HumanizedDateInPastRenderer,
    enableColumnFilter: false,
  },
  {
    header: "Updated at",
    accessorKey: "updatedAt",
    cell: HumanizedDateInPastRenderer,
    enableColumnFilter: false,
  },
  {
    header: "Last graph edit",
    accessorFn: (dataset) => dataset.lastGraphsUpdateTime || "",
    cell: HumanizedDateInPastRenderer,
    enableColumnFilter: false,
  },
  {
    id: "actions",
    header: "",
    cell: DatasetTableActions,
  },
];

const DatasetsTable: React.FC<Props> = ({ datasets, loading, error }) => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const debouncedSetGlobalFilter = useDebounce(setGlobalFilter, 200);
  const [localStorage, setLocalStorage] = useLocalStorage("table-dataset", "{}");
  const ignoreColumnVisibilty = ["name", "actions"];
  const table = useReactTable<Dataset>({
    columns: columns,
    data: datasets,
    state: {
      columnFilters,
      globalFilter,
      columnVisibility: JSON.parse(localStorage),
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getRowId: (ds) => ds.id,
  });
  return (
    <Paper square className={tableStyles.tablePaper}>
      <Toolbar className={getClassName("mb-3 g-7 flex", tableStyles.alignEnd)}>
        <Typography variant="h4">Datasets</Typography>
        <ColumnVisibility
          columns={table.getAllLeafColumns()}
          ignoreVisibility={ignoreColumnVisibilty}
          onChange={(visibility) => setLocalStorage(JSON.stringify(visibility))}
        />
        <div className={tableStyles.space} />
        <MuiTextField
          label="Search"
          onChange={(e) => debouncedSetGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon="search" />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <TableContainer className={tableStyles.tableContainer}>
        <div className="px-5">
          <Table size="small">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <ReactTableBody
              rows={table.getRowModel().rows}
              loading={loading}
              error={error}
              columnCount={columns.length}
            />
            <TableFooter
              currentPage={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              rowCount={table.getPrePaginationRowModel().rows.length}
              onChangePage={table.setPageIndex}
              onChangeRowsPerPage={table.setPageSize}
            />
          </Table>
        </div>
      </TableContainer>
    </Paper>
  );
};

export default DatasetsTable;
