import { Alert, Skeleton } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { FontAwesomeButton, FormField } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import Editor from "./Editors/Editor";
import { FormValues, Property } from "./Types";
import * as styles from "./style.scss";

const factory = factories.compliant;
export interface PropertyModel {
  nodeKind?: "IRI" | "NestedNode" | "Literal";
  datatype?: string;
  type?: string;
  defaultValue?: string;
  defaultValueLabel?: string;
  required?: boolean;
  editor?: string;
  nestedNodeStem?: string;
}

function getDefaultValue(propertyModel: PropertyModel, baseIri: string): Property {
  switch (propertyModel.nodeKind || "Literal") {
    case "IRI":
      return {
        value: propertyModel.defaultValue || "",
        nodeKind: "IRI",
        label: propertyModel.defaultValueLabel || "",
        description: "",
      };
    case "NestedNode":
      return {
        nodeKind: "NestedNode",
        type: propertyModel.type || "",
        value: propertyModel.defaultValue || `${propertyModel.nestedNodeStem || baseIri}i-${uuid()}`,
        properties: {},
      };
    case "Literal":
      return {
        value: propertyModel.defaultValue || "",
        datatype: propertyModel.datatype || "",
        nodeKind: "Literal",
        language: "",
      };
  }
}
const PropertyShape: React.FC<{ propertyShape?: string; propertyPath: string; name: `properties.${string}` }> = ({
  propertyShape,
  propertyPath,
  name,
}) => {
  const { data, error, loading } = useSparql<
    Array<{
      name: string;
      description: string;
      path: string;
      datatype: string;
      type: string;
      nodeKind: "Literal" | "IRI" | "NestedNode";
      defaultValue: string;
      defaultValueLabel: string;
      minCount: string;
      maxCount: string;
      editor: string;
      nestedNodeStem?: string;
    }>
  >(
    !!propertyShape &&
      `
    # PropertyShape
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    prefix skos: <http://www.w3.org/2004/02/skos/core#>
    prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
    prefix dash: <http://datashapes.org/dash#>
    prefix triply: <https://triplydb.com/Triply/function/>

    select
      *
    where {
      bind(${termToString(factory.namedNode(propertyShape))} as ?propertyShape)
      ?propertyShape sh:path ?path .
      optional {
        ?propertyShape sh:name ?name .
      }
      optional {
        ?path rdfs:label ?name .
      }
      optional {
        ?propertyShape sh:description ?description
      }
      optional {
        ?path rdfs:comment ?description
      }
      optional {
        ?propertyShape sh:datatype ?datatype .
      }
      optional {
        ?propertyShape sh:class ?type .
        optional {
          ?propertyShape dash:editor dash:DetailsEditor .
          bind(true as ?nestedNode)

          optional {
            ?nestedNodeShape sh:targetClass ?type .
            ?nestedNodeShape dash:stem ?nestedNodeStem .
          }
        }
      }
      bind(if(bound(?type), if(bound(?nestedNode), "NestedNode", "IRI"), "Literal") as ?nodeKind)
      optional {
        ?propertyShape sh:minCount ?minCount .
      }
      optional {
        ?propertyShape sh:maxCount ?maxCount .
      }
      optional {
        ?propertyShape sh:defaultValue ?defaultValue .
        bind(triply:firstLabel(?defaultValue) as ?defaultValueLabel)
      }
      optional {
        ?propertyShape dash:editor ?editor .
      }
    }
    limit 1
    `,
  );

  const applyPrefixes = useApplyPrefixes();
  const currentDs = useCurrentDataset()!;
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${currentDs.owner.accountName}/${currentDs.name}` });
  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "id")?.iri || `${datasetUrl}/id/`;

  const { control, getFieldState, unregister } = useFormContext<FormValues>();

  const minCount = Number(data?.[0]?.minCount) || 0;
  const maxCount = Number(data?.[0]?.maxCount) || Number.MAX_SAFE_INTEGER;

  const label = data?.[0]?.name || applyPrefixes(propertyPath);

  const { fields, remove, append, move } = useFieldArray<FormValues>({
    name: name,
    control,
    rules: {
      validate: (value: Array<Property | null> | undefined) => {
        const nonEmptyValues = value?.filter((val) => val?.value) || [];
        const count = nonEmptyValues.length;
        if (minCount === 1 && count === 0) {
          return `A value for '${label}' is required.`;
        }
        if (count < minCount) {
          return `${minCount} values are required for '${label}'.`;
        }
        if (maxCount === 1 && count > maxCount) {
          return `Only one value is allowed for '${label}'.`;
        }
        if (count > maxCount) {
          return `Not more than ${maxCount} values are allowed for '${label}'.`;
        }
        return undefined;
      },
    },
  });

  if (loading) return <Skeleton />;

  if (error) return <Alert severity="error">Failed to load.</Alert>;

  if (propertyShape && (!data || !data[0])) return null;

  const count = fields.length;

  const errors = getFieldState(name).error;
  const errorMessage = errors?.root?.message;
  const containsError = !!errorMessage || (errors as any)?.length > 0;

  const propertyModel: PropertyModel = {
    nodeKind: data?.[0]?.nodeKind,
    datatype: data?.[0]?.datatype,
    type: data?.[0]?.type,
    defaultValue: data?.[0]?.defaultValue,
    defaultValueLabel: data?.[0]?.defaultValueLabel,
    editor: data?.[0]?.editor,
    nestedNodeStem: data?.[0]?.nestedNodeStem,
  };

  const required = minCount > 0;

  return (
    <FormField
      className={getClassName(styles.formField, { [styles.error]: containsError })}
      label={label}
      helperText={data?.[0]?.description}
      required={required}
      breakPoint={1}
    >
      <div className="flex column g-7">
        <div className="flex g-7">
          <div className="flex column g-7 grow">
            {fields.map((field, index) => {
              const fieldName = `${name}.${index}` as const;
              return (
                <div key={field.id} className="flex g-5">
                  <Editor name={fieldName} propertyModel={propertyModel} />
                  <div className="flex center">
                    <FontAwesomeButton
                      color="error"
                      icon="trash"
                      title="Remove value"
                      onClick={() => {
                        // Reorder the item to the last place, so that we can unregister it. The unregister would otherwise apply to the next item.
                        const lastPlace = fields.length - 1;
                        move(index, lastPlace);
                        remove(lastPlace);
                        // unregister is needed to remove validation. Without setTimeout values of fields can get mixed up.
                        setTimeout(() => unregister(`${name}.${lastPlace}`), 0);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {propertyShape && count < maxCount && (
            <div className={styles.appendField}>
              <FontAwesomeButton
                icon="plus"
                onClick={() =>
                  append(getDefaultValue(propertyModel, baseIri), { focusName: `${name}.${fields.length}` })
                }
                title={`Add new value for '${label}'`}
              />
            </div>
          )}
        </div>

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </FormField>
  );
};

export default PropertyShape;
